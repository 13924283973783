import {
  Box,
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { removeFromLocalStorage } from "utils";
import { useAuth } from "hooks";
import { useShareAccept } from "api"
import { ShareAcceptType } from "duck";

interface SharedDeviceProps {
  sharingToken: string;
  refetch: Function
}

const SharedDevice: React.FC<SharedDeviceProps> = ({ sharingToken, refetch }) => {
  const { auth } = useAuth();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const acception = () => {
    removeFromLocalStorage("sharingToken");
    refetch();
  }

  const { response, error, loading, fetch }: ShareAcceptType = useShareAccept(
    auth.token,
    auth.user?.sub,
    sharingToken,
    acception
  );

  const handleAddDeviceButton = () => {
    fetch()
  };

  const handleCancelButton = () => {
    removeFromLocalStorage("sharingToken");
    refetch();
  };

  return (
    <Box h="100%">
      <Flex
        flexDirection="column"
        h="100%"
        w="100%"
        alignItems="center"
        justifyContent="center"
      >
        {/* Modals */}
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay backdropFilter="blur(1.5px)" />
          <ModalContent mx={4}>
            <ModalHeader textAlign="center">New device</ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody>
              A device has been shared with you. You can add it to your device
              list.
            </ModalBody>
            <ModalFooter flexDirection="column">
              <Flex gap={4} justifyContent="center" flex="1">
                <Button
                  leftIcon={<FiPlus />}
                  colorScheme="blue"
                  onClick={() => handleAddDeviceButton()}
                  isLoading={loading}
                >
                  Add device
                </Button>
                <Button
                  colorScheme="red"
                  variant="ghost"
                  onClick={() => handleCancelButton()}
                >
                  Cancel
                </Button>
              </Flex>
              {error && (
                <Text mt={4} textAlign="center">
                  Something went wrong! Try again later.
                </Text>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};

export default SharedDevice;
