import React from 'react'
import { Flex, Spinner } from '@chakra-ui/react';

type PreloaderProps = {
  text?: string;
  empty?: boolean;
};

const Preloader = ({ text = "Authorization Check", empty }: PreloaderProps) => {
  return (
    <>
      <Flex
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        h="100%"
      >
        <Spinner
          thickness="2px"
          speed="0.6s"
          emptyColor="gray.200"
          color="blue.500"
          size="lg"
          mb={4}
        />
        {!empty && text}
      </Flex>
    </>
  );
};

export default Preloader