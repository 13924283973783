import React from 'react'
import { Dayjs } from "dayjs";
import { Text, Flex, IconButton, useColorMode } from "@chakra-ui/react";
import { getPeriodTimeString } from "utils";
import { AiOutlineLeft, AiOutlineRight, AiOutlineReload } from "react-icons/ai";
import { BoxWrapper } from 'components/ui';

type PeriodBarProps = {
  scale: string;
  period: number;
  setPeriod: React.Dispatch<React.SetStateAction<number>>;
  tillTime: Dayjs;
  sinceTime: Dayjs;
  reload: Function;
};

const PeriodBar = ({ scale, period, setPeriod, tillTime, sinceTime, reload }: PeriodBarProps) => {
  const periodString = getPeriodTimeString(tillTime, sinceTime, scale, period);
  const { colorMode } = useColorMode();
  return (
    <BoxWrapper noPadding noMargin>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" justifyContent="space-between" flex="1" p="1">
          <IconButton
            icon={<AiOutlineLeft />}
            aria-label="Previous period"
            size="md"
            borderRadius="100%"
            backgroundColor={colorMode === "dark" ? "whiteAlpha.300" : "white"}
            _hover={{
              backgroundColor: `${
                colorMode === "dark" ? "whiteAlpha.300" : "white"
              }`,
            }}
            color={colorMode === "dark" ? "white" : "black"}
            onClick={() => setPeriod(period - 1)}
          />

          <Text fontSize="sm" fontWeight="600" textAlign="center" lineHeight="1.1" px={1}>
            {periodString}
          </Text>
          <IconButton
            icon={<AiOutlineRight />}
            aria-label="Next period"
            size="md"
            borderRadius="100%"
            backgroundColor={colorMode === "dark" ? "whiteAlpha.300" : "white"}
            _hover={{
              backgroundColor: `${
                colorMode === "dark" ? "whiteAlpha.300" : "white"
              }`,
            }}
            color={colorMode === "dark" ? "white" : "black"}
            _disabled={{ opacity: 0.3 }}
            onClick={() => setPeriod(period + 1)}
            disabled={period >= 0 ? true : false}
          />
        </Flex>
        <IconButton
          icon={<AiOutlineReload />}
          aria-label="Reload"
          size="md"
          onClick={() => {setPeriod(0);reload()}}
          borderRadius="100%"
          backgroundColor={colorMode === "dark" ? "whiteAlpha.300" : "white"}
          _hover={{
            backgroundColor: `${
              colorMode === "dark" ? "whiteAlpha.300" : "white"
            }`,
          }}
          color={colorMode === "dark" ? "white" : "black"}
          mr="1"
          display={period >= 0 ? "none" : "flex"}
        />
      </Flex>
    </BoxWrapper>
  );
};

export default PeriodBar