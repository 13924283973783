import React from 'react'
import { Box, useColorMode } from "@chakra-ui/react";

interface BoxWrapperProps {
  children: React.ReactNode;
  noPadding?: boolean;
  noMargin?: boolean;
  bordered?: boolean;
  smallRadius?: boolean;
  props?: { [key: string]: any };
}

const BoxWrapper: React.FC<BoxWrapperProps> = ({children, noPadding, noMargin, smallRadius, props}) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      pt={!noPadding ? "4" : "0"}
      pb={!noPadding ? "4" : "0"}
      borderRadius={smallRadius ? {base: "15px", md: "25px"} : "25px"} 
      // border="2px solid"
      // borderColor="gray.100"
      backgroundColor={colorMode === "light" ? "gray.100" : "whiteAlpha.200"}
      mb={!noMargin ? "4" : "0"}
      overflow="hidden"
      {...props}
    >
      {children}
    </Box>
  );
};

export default BoxWrapper