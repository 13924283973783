import React from "react";
import { Header } from "../";
import { Outlet } from "react-router-dom";
import { Box, Flex, useColorMode } from "@chakra-ui/react";

interface LayoutProps {
  children?: React.ReactNode,
  empty?: boolean,
}

const Layout: React.FC<LayoutProps> = ({children, empty}) => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      position="relative"
      w="100%"
      h="100vh"
      alignItems="center"
      justifyContent="center"
      bgColor={colorMode === "dark" ? "gray.700" : "gray.200"}
    >
      <Box
        maxW="1400px"
        w="100%"
        h={{ base: "100vh", lg: "calc(100vh - 48px)" }}
        m={{ base: 0, lg: 4 }}
        bgColor={colorMode === "dark" ? "gray.800" : "white"}
        borderRadius={{ base: 0, lg: 40 }}
        overflow="hidden"
        boxShadow="2xl"
      >
        <Flex
          h="calc(100vh - 48px)"
          direction={{ base: "column" }}
          overflow="hidden"
        >
          <Flex flex="1">
            <Box
              position="relative"
              as="main"
              w="full"
              overflow="hidden"
              height="calc(100vh - 48px)"
              overflowY="auto"
            >
              {!empty && <Header />}
              {children}
              <Outlet />
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Layout;
