import React from 'react'
import { ColorModeSwitcher } from "components";
import { Box, Flex, Text, Image, useColorMode } from "@chakra-ui/react";
import logoImage from "assets/images/logo.svg";

function Header() {
  const { colorMode } = useColorMode();
  return (
    <Box as="header" w="100%" mb={{ base: 4, md: 6 }} pr={{ base: 4, lg: 6 }}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex
          justifyContent="center"
          backgroundColor={
            colorMode === "light"
              ? { base: "none", md: "gray.100" }
              : { base: "none", md: "whiteAlpha.200" }
          }
          borderRadius="25"
          px={{ base: 0, md: 6 }}
          py={{ base: 0, md: 2 }}
        >
          <Image src={logoImage} alt="logo" w="24px" mr={2} />
          <Text fontWeight="600" fontSize="lg">
            BALLOONAIR
          </Text>
        </Flex>
        <ColorModeSwitcher />
      </Flex>
    </Box>
  );
}

export default Header