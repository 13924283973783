import React from 'react'
import dayjs from "dayjs";
import {
  Box,
  Flex,
  Text,
  Grid,
  GridItem,
  CircularProgress,
  CircularProgressLabel,
  useColorMode,
} from "@chakra-ui/react";
import { useAppSettings } from 'hooks';
import { getTempValue, getCo2Level } from "utils";
import { BoxWrapper } from 'components/ui';
import { HiOutlineArrowUp } from "react-icons/hi"; 
import { GiPauseButton } from "react-icons/gi"; 


interface LastDataProps {
  data: any;
  reloadSeconds: {pause: boolean, val: number};
  onlineStatus: boolean
}

const LastData: React.FC<LastDataProps> = ({
  data,
  reloadSeconds,
  onlineStatus,
}) => {
  const { tempUnitsValue } = useAppSettings();
  const { colorMode } = useColorMode();
  const date = dayjs(data.date).local().format("MMMM DD HH:mm");

  return (
    <Box mb="4">
      <Grid
        templateRows="1fr, auto"
        templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(4, 1fr)" }}
        gap={{ base: 2, md: 4 }}
      >
        <GridItem colSpan={3} order={{ base: 1, md: 0 }}>
          <Grid
            gap={{ base: 2, md: 4 }}
            templateRows="1fr, 1fr"
            templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(3, 1fr)" }}
          >
            <GridItem colSpan={{ base: 2, md: 1 }} rowSpan={{ base: 2, md: 1 }}>
              <BoxWrapper noMargin noPadding smallRadius props={{ h: "100%" }}>
                <Flex
                  flexDirection="column"
                  px={{ base: 3, md: 4 }}
                  py={{ base: 2, md: 3 }}
                  h="100%"
                >
                  <Text
                    fontWeight="600"
                    fontSize={{ base: "xs", md: "sm" }}
                    color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
                  >
                    CO<sub>2</sub>
                  </Text>
                  <Flex
                    flex={1}
                    justifyContent="center"
                    alignItems="center"
                    mt="1"
                    mb="2"
                  >
                    <Flex justifyContent="center" alignItems="end">
                      <Text
                        fontWeight="700"
                        mr="1"
                        fontSize={{ base: "5xl", md: "4xl" }}
                        lineHeight="1.05"
                      >
                        {onlineStatus ? data.values.co2.value : "----"}
                      </Text>
                      <Text>ppm</Text>
                    </Flex>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    alignItems="end"
                    minH="16px"
                  >
                    {onlineStatus && (
                      <Box
                        transform={
                          data.values.co2.trend === "up" ? "" : "rotate(180deg)"
                        }
                        color={
                          colorMode === "dark" ? "primary.300" : "primary.500"
                        }
                      >
                        <HiOutlineArrowUp />
                      </Box>
                    )}
                    {onlineStatus && (
                      <Text
                        fontWeight="600"
                        fontSize="xs"
                        color={
                          colorMode === "dark" ? "whiteAlpha.700" : "gray.500"
                        }
                        lineHeight="1.05"
                        textAlign="right"
                      >
                        {getCo2Level(data.values.co2.value)}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </BoxWrapper>
            </GridItem>
            <GridItem>
              <BoxWrapper noMargin noPadding smallRadius>
                <Box px={{ base: 3, md: 4 }} py={{ base: 2, md: 3 }}>
                  <Text
                    fontWeight="600"
                    fontSize={{ base: "xs", md: "sm" }}
                    color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
                  >
                    Temperature
                  </Text>
                  <Flex
                    justifyContent="center"
                    alignItems="end"
                    mt="1"
                    mb={{ base: 1, md: 2 }}
                  >
                    <Text
                      fontWeight="700"
                      mr="1"
                      fontSize={{ base: "2xl", md: "4xl" }}
                      lineHeight="1.05"
                    >
                      {onlineStatus
                        ? getTempValue(data.values.temp.value, tempUnitsValue)
                        : "--"}
                    </Text>
                    <Text>{tempUnitsValue === "celsius" ? "°С" : "°F"}</Text>
                  </Flex>
                  <Flex justifyContent="start" minH="16px">
                    {onlineStatus && (
                      <Box
                        transform={
                          data.values.temp.trend === "up"
                            ? ""
                            : "rotate(180deg)"
                        }
                        color={
                          colorMode === "dark" ? "primary.300" : "primary.500"
                        }
                      >
                        <HiOutlineArrowUp />
                      </Box>
                    )}
                  </Flex>
                </Box>
              </BoxWrapper>
            </GridItem>
            <GridItem>
              <BoxWrapper noMargin noPadding smallRadius>
                <Box px={{ base: 3, md: 4 }} py={{ base: 2, md: 3 }}>
                  <Text
                    fontWeight="600"
                    fontSize={{ base: "xs", md: "sm" }}
                    color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
                  >
                    Humidity
                  </Text>
                  <Flex
                    justifyContent="center"
                    alignItems="end"
                    mt="1"
                    mb={{ base: 1, md: 2 }}
                  >
                    <Text
                      fontWeight="700"
                      mr="1"
                      fontSize={{ base: "2xl", md: "4xl" }}
                      lineHeight="1.05"
                    >
                      {onlineStatus ? data.values.hum.value.toFixed(1) : "--"}
                    </Text>
                    <Text>%</Text>
                  </Flex>
                  <Flex justifyContent="start" minH="16px">
                    {onlineStatus && (
                      <Box
                        transform={
                          data.values.hum.trend === "up" ? "" : "rotate(180deg)"
                        }
                        color={
                          colorMode === "dark" ? "primary.300" : "primary.500"
                        }
                      >
                        <HiOutlineArrowUp />
                      </Box>
                    )}
                  </Flex>
                </Box>
              </BoxWrapper>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem colSpan={{ base: 3, md: 1 }} order={{ base: 0, md: 3 }}>
          {onlineStatus && (
            <Flex
              flexDirection={{ base: "row", md: "column" }}
              alignItems={{ base: "center", md: "end" }}
              justifyContent="end"
            >
              <Flex
                flexDirection="column"
                justifyContent={{ base: "center", md: "end" }}
                mr={{ base: 2, md: 0 }}
              >
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  textAlign="right"
                  lineHeight="1.1"
                  color={
                    colorMode === "dark" ? "rgba(255,255,255, 0.64)" : "#718096"
                  }
                >
                  Last update:
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  textAlign="right"
                  lineHeight="1.1"
                >
                  {date}
                </Text>
              </Flex>

              <CircularProgress
                value={100 - Math.round((100 / 60) * reloadSeconds.val)}
                color={colorMode === "dark" ? "secondary.500" : "primary.500"}
                trackColor={
                  colorMode === "dark" ? "whiteAlpha.300" : "gray.200"
                }
                size="2.5rem"
                mt={{ md: 2 }}
              >
                <CircularProgressLabel display="flex" justifyContent="center">
                  {reloadSeconds.pause === false ? (
                    `${reloadSeconds.val}s`
                  ) : (
                    <GiPauseButton />
                  )}
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
};

export default LastData