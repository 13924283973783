import { useState } from "react";
import axios from "axios";

type Props = {
  token: string | null | undefined;
  userId: string | undefined;
  callback?: Function;
};


const useRemoveUser = ({ token, userId, callback }: Props) => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(false);

  const baseUrl = process.env.REACT_APP_API_URL;

  const fetch = () => {
    setloading(true);
    axios({
      method: "delete",
      url: `${baseUrl}/api/account/${userId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setloading(false);
        if (callback) callback();
      });
  };

  // custom hook returns value
  return { response, error, loading, fetch };
};

export default useRemoveUser;
