import React from 'react'
import { DeviceType, DeviceLastDataResponseType } from "duck";
import {
  Box,
  Flex,
  Heading,
  Text,
  Divider,
  Badge,
  Spinner,
  Tag,
  IconButton,
  useColorMode,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { useAuth } from "hooks";
import { useDeviceLastData } from 'api';
import { setToLocalStorage, getOnlineStatus, getTempValue } from "utils";
import { AiOutlineSetting, AiOutlineBarChart } from "react-icons/ai";

interface DeviceItemProps {
  data: DeviceType;
  tempUnitsValue: string;
  activeDeviceId: string | null;
  setActiveDeviceId: React.Dispatch<React.SetStateAction<string>>;
}

const DeviceItem: React.FC<DeviceItemProps> = ({
  data,
  tempUnitsValue,
  activeDeviceId,
  setActiveDeviceId,
}) => {
  const handleClick = () => {
    setToLocalStorage("activeDeviceId", data.deviceId);
    setActiveDeviceId(data.deviceId);
  };
  const { auth } = useAuth();
  const { colorMode } = useColorMode();
  const { loading, response, error }: DeviceLastDataResponseType = useDeviceLastData(
    auth.token,
    auth.user?.sub,
    data.deviceId
  );
  const onlineStatus = React.useMemo(() => getOnlineStatus(data), [data]);

  return (
    <Box
      pl={{ base: 3, md: 6 }}
      pr={{ base: 2, md: 4 }}
      pt={{ base: 2, md: 3 }}
      pb="4"
      mx={{ base: 3, md: 4 }}
      rounded="md"
      borderRadius={{ base: "10px", md: "15px" }}
      backgroundColor={colorMode === "light" ? "white" : "whiteAlpha.200"}
      border={
        activeDeviceId === data.deviceId
          ? "1px solid #00b2ea"
          : "1px solid transparent"
      }
      cursor="pointer"
      transition="all 0.3s ease"
      position="relative"
      _hover={{ border: "1px solid #00b2ea" }}
      onClick={() => handleClick()}
    >
      <Flex mb="1" alignItems={{ md: "center" }} justifyContent="space-between">
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          alignItems={{ base: "start", md: "center" }}
          mt={{ base: 2, md: 0 }}
        >
          <Heading
            as="h2"
            size="md"
            noOfLines={1}
            mr="2"
            mb={{ base: 2, md: 0 }}
          >
            {data.name}
          </Heading>
          <Flex alignItems="center" mr={2}>
            {onlineStatus ? (
              <Badge
                variant="outline"
                colorScheme="green"
                borderRadius="10"
                px="2"
              >
                Online
              </Badge>
            ) : (
              <Badge
                variant="outline"
                colorScheme="red"
                borderRadius="10"
                px="2"
              >
                Offline
              </Badge>
            )}
            {data.mode && (
              <Badge
                colorScheme="purple"
                variant="outline"
                ml="1"
                borderRadius="10"
                px="2"
              >
                {data.mode === "Master" ? "My device" : "Shared"}
              </Badge>
            )}
          </Flex>
        </Flex>
        <Flex flexDirection={{ base: "column", md: "row" }}>
          <IconButton
            as={ReactRouterLink}
            icon={<AiOutlineBarChart />}
            onClick={(e) => e.stopPropagation()}
            to={`/charts/${data.deviceId}`}
            aria-label="Device settings"
            size="md"
            mr={{ md: 1 }}
            borderRadius="100%"
            order={{ base: 1, md: 0 }}
          />
          <IconButton
            as={ReactRouterLink}
            icon={<AiOutlineSetting />}
            onClick={(e) => e.stopPropagation()}
            to={`/devices/${data.deviceId}/settings`}
            aria-label="Device settings"
            size="md"
            borderRadius="100%"
            mb={{ base: 1, md: 0 }}
            order={{ base: 0, md: 1 }}
          />
        </Flex>
      </Flex>
      {loading && !response.date && (
        <Spinner
          thickness="2px"
          speed="0.6s"
          emptyColor="gray.200"
          color="primary.500"
          size="sm"
          mt="1"
        />
      )}
      {!loading && (
        <Flex>
          <Flex alignItems="end">
            <Text fontSize="xl" fontWeight="700" mr="1" lineHeight="1.3">
              {onlineStatus && response.values
                ? response.values.co2.value
                : "----"}
            </Text>
            <Text>ppm</Text>
            <Divider orientation="vertical" mr="2" ml="2" />
          </Flex>
          <Flex alignItems="end">
            <Text fontSize="xl" fontWeight="700" mr="1" lineHeight="1.3">
              {onlineStatus && response.values
                ? getTempValue(response.values.temp.value, tempUnitsValue)
                : "--"}
            </Text>
            <Text>{tempUnitsValue === "celsius" ? "°С" : "°F"}</Text>
            <Divider orientation="vertical" mr="2" ml="2" />
          </Flex>
          <Flex alignItems="end">
            <Text fontSize="xl" fontWeight="700" mr="1" lineHeight="1.3">
              {onlineStatus && response.values
                ? response.values.hum.value.toFixed(1)
                : "--"}
            </Text>
            <Text>%</Text>
          </Flex>
        </Flex>
      )}
      {error && (
        <Flex>
          <Text>Loading Error</Text>
        </Flex>
      )}
      <Tag
        borderRadius="full"
        variant="solid"
        background="secondary.500"
        textTransform="uppercase"
        fontWeight="600"
        fontSize="xs"
        position="absolute"
        right="5"
        bottom="-3"
        pl="4"
        pr="4"
        opacity={activeDeviceId === data.deviceId ? "1" : "0"}
      >
        Active
      </Tag>
    </Box>
  );
};

export default DeviceItem