import { Route, Routes } from "react-router-dom";
import { MainSettings, AccountSettings } from "./components";

const AppSettings = () => {
  return (
    <Routes>
      {/* <Route path="/:deviceId" /> */}
      <Route path="/" element={<MainSettings />} />
      <Route path="/account" element={<AccountSettings />} />
    </Routes>
  );
};

export default AppSettings;
