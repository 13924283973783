import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "hooks";
import { Login, AuthCallback, Cabinet, Layout, Preloader } from "components";

export const App = () => {
  const { auth } = useAuth();

  if (auth.isAuthenticating) {
    return (
      <Layout empty>
        <Preloader />
      </Layout>
    );
  }

  return (
    <>
      {auth.isLoggedIn ? (
        <Cabinet />
      ) : (
        <Routes>
          <Route element={<Layout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/auth-callback" element={<AuthCallback />} />
            <Route path="/" element={<Navigate to="login" />} />
            <Route path="*" element={<Navigate to="login" />} />
          </Route>
        </Routes>
      )}
    </>
  );
};

export default App;
