import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "duck"
import { AuthContext, AuthState } from "duck";
import { setToLocalStorage } from "utils";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "api"

interface AuthProviderType {
  children: any;
}

const AuthProvider: React.FC<AuthProviderType> = ({ children }) => {

  const [searchParams] = useSearchParams();
  const sharingToken = searchParams.get("SharingToken");
  sharingToken && setToLocalStorage("sharingToken", sharingToken);

  const [auth, setAuth] = React.useState<AuthState>({
    isAuthenticating: true,
    isLoggedIn: false,
    user: null,
    token: localStorage.getItem("token"),
  });

  const { response, error } = useAuth(auth.token);

  const signInCheck = React.useCallback(() => {
    if (response) {
      setAuth({
        isAuthenticating: false,
        isLoggedIn: true,
        user: response,
        token: localStorage.getItem("token"),
      });
    } 
    // eslint-disable-next-line
  }, [setAuth, response]);

  React.useEffect(() => {
    signInCheck();
  }, [signInCheck]);

  React.useEffect(() => {
    if(error) {
      setAuth({
        isAuthenticating: false,
        isLoggedIn: false,
        user: null,
      });
    }
  }, [setAuth, error]);

  return (
    <ChakraProvider theme={theme}>
      <AuthContext.Provider
        value={{
          auth,
          setAuth,
        }}
      >
        {children}
      </AuthContext.Provider>
    </ChakraProvider>
  );
};

export default AuthProvider;
