import { Box, Text, useColorMode, useBreakpointValue } from "@chakra-ui/react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { BoxWrapper } from "components/ui";
import {
  getDataArrayByMetric,
  getFromLocalStorage,
  getXAxisTime,
  getTooltipTime,
  getThemeByApp,
} from "utils";

interface Co2Props {
  data: [];
  scale: string;
}

const Co2 = ({ data, scale }: Co2Props) => {
  const theme = getFromLocalStorage("chartsScheme") || "app";
  const { colorMode } = useColorMode();
  const textColor = colorMode === "dark" ? "rgba(255,255,255, 0.64)" : "#718096"
  const dataset = getDataArrayByMetric(data, "co2", getThemeByApp(theme, colorMode));
  const formatXAxis = (value: string, index: number) => {
    return getXAxisTime(value, scale);
  };
  const fontSizeValue = useBreakpointValue(
    {
      base: "10px",
      lg: "12px",
    },
    {
      fallback: "base",
    }
  );
  const barSizeValue = useBreakpointValue(
    {
      base: 3,
      lg: 6,
    },
    {
      fallback: "base",
    }
  );
  return (
    <Box width="100%">
      <BoxWrapper noMargin noPadding smallRadius>
        <Box
          py={{ base: 2, md: 3 }}
          pl={{ base: 3, md: 4 }}
          pr={{ base: 0, md: 2 }}
        >
          <Text
            fontWeight="600"
            fontSize={{ base: "xs", md: "sm" }}
            color={textColor}
          >
            CO<sub>2</sub>
          </Text>
          <Box width="100%" height={{ base: "180px", md: "281px" }}>
            <ResponsiveContainer width="99%" height="100%">
              <BarChart
                data={dataset}
                barGap={barSizeValue}
                syncId="charts"
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis
                  dataKey="date"
                  axisLine={false}
                  tickSize={0}
                  tick={{ fontSize: fontSizeValue, fill: textColor }}
                  tickCount={0}
                  height={20}
                  tickMargin={10}
                  padding={{ left: 0, right: 4 }}
                  tickFormatter={formatXAxis}
                />
                <YAxis
                  orientation="right"
                  axisLine={false}
                  tickSize={0}
                  tick={{ fontSize: fontSizeValue, fill: textColor }}
                  width={32}
                  type="number"
                  domain={[
                    (dataMin: number) => (dataMin = 400),
                    // 400,
                    (dataMax: number) => Math.ceil(dataMax / 200) * 200,
                  ]}
                />
                <Tooltip
                  content={<CustomTooltip scale={scale} />}
                  cursor={{ opacity: 0.2 }}
                  offset={10}
                  // position={{ y: 10 }}
                />
                <Bar
                  dataKey="valueShow"
                  isAnimationActive={true}
                  minPointSize={1}
                  animationDuration={500}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={dataset[index].color} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </BoxWrapper>
    </Box>
  );
};

const CustomTooltip = ({ active, payload, label, scale }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box>
        <Text fontSize="md" fontWeight="600" lineHeight="1.1">
          {getTooltipTime(label, scale)}
        </Text>
        <Text fontSize="xl" fontWeight="800" lineHeight="1.1">
          {payload[0].payload.value}{" "}
          <Text as="span" fontSize="sm">
            ppm
          </Text>
        </Text>
      </Box>
    );
  }

  return null;
};

export default Co2;
