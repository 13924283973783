import { useState, useEffect } from "react";
import axios from "axios";


const useDeviceSettings = (
  token: string | null | undefined,
  userId: string | undefined,
  deviceId: string | undefined,
) => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(true);

  const baseUrl = process.env.REACT_APP_API_URL;

  const fetch = () => {
    setloading(true);
    axios({
      method: "get",
      url: `${baseUrl}/api/devices/${userId}/${deviceId}/settings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // custom hook returns value
  return { response, error, loading, fetch };
};

export default useDeviceSettings;
