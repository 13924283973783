import React from 'react'
import pkceChallenge from "pkce-challenge";
import {
  Box,
  Flex,
  Button,
  Image,
  Text,
  Link,
  useColorMode,
} from "@chakra-ui/react";
import { useAuth } from "hooks";
import roundImage from "assets/images/back-rounds.svg";
import { FaGoogle, FaApple } from "react-icons/fa";

const Login: React.FC = () => {
  const { code_verifier, code_challenge } = pkceChallenge();
  localStorage.setItem("code_verifier", code_verifier);
  localStorage.setItem("code_challenge", code_challenge);
  localStorage.removeItem("activeDeviceId");
  const { setAuth } = useAuth();
  const { colorMode } = useColorMode();
  const handleButton = () => {
    setAuth({
      isAuthenticating: true,
      isLoggedIn: false,
      user: null,
    });
  }
  const callbackUrl = process.env.REACT_APP_CALLBACK_URL;

  const googleLink = `https://iot.balloon.by/connect/authorize?acr_values=idp:google&prompt=select_account&response_type=code&client_id=web.app&scope=openid+profile+email+balloon.iot.device.api+device.read+device.write+balloon.iot.data.api+data.read+balloon.iot.push.api+push.manage+balloon.iot.account.api+account.delete&redirect_uri=${callbackUrl}&code_challenge=${code_challenge}&code_challenge_method=S256`;

  const appleLink = `https://iot.balloon.by/connect/authorize?acr_values=idp:apple&prompt=select_account&response_type=code&client_id=web.app&scope=openid+profile+email+balloon.iot.device.api+device.read+device.write+balloon.iot.data.api+data.read+balloon.iot.push.api+push.manage+balloon.iot.account.api+account.delete&redirect_uri=${callbackUrl}&code_challenge=${code_challenge}&code_challenge_method=S256`;

  return (
    <Flex h="100%" direction={{ base: "column", lg: "row" }} overflow="auto">
      <Flex
        minH="50%"
        flexDirection="column"
        py={20}
        px={10}
        alignItems="center"
        justifyContent="center"
        flex={1}
      >
        <Box minW="300px">
          <Button
            as="a"
            href={googleLink}
            leftIcon={<FaGoogle />}
            colorScheme="blue"
            onClick={handleButton}
            w="100%"
            mb="1rem"
          >
            Sign up with Google
          </Button>
          <Button
            as="a"
            href={appleLink}
            leftIcon={<FaApple />}
            colorScheme="blue"
            onClick={handleButton}
            w="100%"
          >
            Sign up with Apple
          </Button>
          <Text mt="6" textAlign="center">
            By logging in, you agree{" "}
            <Link
              href="https://balloonair.tech/legal/app-privacy-policy"
              isExternal
              textDecor="underline"
            >
              with Privacy Policy
            </Link>
          </Text>
        </Box>
      </Flex>
      <Box
        flex={1}
        minH="50%"
        display={{ base: "none", lg: "block" }}
        bgGradient={
          colorMode === "light"
            ? "linear(to-r, blue.500, secondary.500)"
            : "linear(to-r, whiteAlpha.50, whiteAlpha.100)"
        }
        position="relative"
        overflow="hidden"
        borderBottomLeftRadius="140"
      >
        <Box position="absolute" top="0" right="0" w="100%" h="100%">
          <Image
            src={roundImage}
            alt="Round"
            w="200%"
            maxW="none"
            transform="rotate(45deg)"
            mt="-40%"
            ml="-20%"
            opacity={colorMode === "light" ? "1" : "0.5"}
          />
        </Box>
      </Box>
    </Flex>
  );
}

export default Login;
