import { FormControl, FormLabel, Select, useColorMode } from "@chakra-ui/react";
import { useSetDeviceSetting } from "api";
import { ListItem } from "components/ui";

type ColorSchemaSelectorProps = {
  token: string | null | undefined;
  userId: string | undefined;
  deviceId: string | undefined;
  alias: string;
  data: string;
};

const ColorSchemaSelector = ({
  token,
  userId,
  deviceId,
  alias,
  data,
}: ColorSchemaSelectorProps) => {
  const colorSchema = JSON.parse(data).schema;
  const { colorMode } = useColorMode();

  // TODO add loading and error check
  const { fetch } = useSetDeviceSetting(token, userId, deviceId);

  const handleInputChange = (e: string) => {
    fetch({ alias: alias, data: { schema: e } });
  };

  return (
    <ListItem>
      <FormControl>
        <FormLabel
          htmlFor="colorShema"
          mb="1"
          fontSize="sm"
          color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
        >
          Color scheme
        </FormLabel>
        <Select
          id="colorShema"
          variant="filled"
          defaultValue={colorSchema}
          onChange={(e) => handleInputChange(e.target.value)}
        >
          <option value="hsv_invert">Green-Yellow-Red</option>
          <option value="hsv">Green-Blue-Red</option>
        </Select>
      </FormControl>
    </ListItem>
  );
};

export default ColorSchemaSelector;
