import { FormControl, FormLabel, Select, useColorMode } from "@chakra-ui/react";
import { useSetDeviceSetting } from "api";
import { co2ThresholdData } from "duck";
import { ListItem } from "components/ui";

type Co2LevelAlertSelectorProps = {
  token: string | null | undefined;
  userId: string | undefined;
  deviceId: string | undefined;
  alias: string;
  data: string;
};

const Co2LevelAlertSelector = ({
  token,
  userId,
  deviceId,
  alias,
  data,
}: Co2LevelAlertSelectorProps) => {
  const { colorMode } = useColorMode();
  const level = JSON.parse(data).threshold;

  // TODO add loading and error check
  const { fetch } = useSetDeviceSetting(token, userId, deviceId);

  const handleInputChange = (e: string) => {
    fetch({ alias: alias, data: { ...JSON.parse(data), threshold: Number(e) } });
  };

  return (
    <ListItem last>
      <FormControl>
        <FormLabel
          htmlFor="co2LevelData"
          mb="1"
          fontSize="sm"
          color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
        >
          CO<sub>2</sub> level alert
        </FormLabel>
        <Select
          id="co2LevelData"
          variant="filled"
          defaultValue={level}
          onChange={(e) => handleInputChange(e.target.value)}
        >
          {co2ThresholdData.map((item) => (
            <option key={item.data} value={item.data}>
              {item.value}
            </option>
          ))}
        </Select>
      </FormControl>
    </ListItem>
  );
};

export default Co2LevelAlertSelector;
