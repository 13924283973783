import {
  Box,
  Flex,
  Text,
  Button,
  Modal,
  Spinner,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { SharingInput } from "./components";
import { ListItem, BoxWrapper } from "components/ui";
import {
  useSetShareDevice,
  useCancelShareDevice
} from "api";
import { ShareStatusType } from "duck";

type SharingBlockProps = {
  data: ShareStatusType;
  token: string | null | undefined;
  userId: string | undefined;
  fetch: Function;
};

const SharingBlock = ({ data, token, userId, fetch }: SharingBlockProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenLink,
    onOpen: onOpenLink,
    onClose: onCloseLink,
  } = useDisclosure();
  const { activeTokensCount, deviceId, sharedWithCount } = data;

  const afterCancel = () => {
    onClose();
    fetch();
  };

  const handeCloseSharingModal = () => {
    onCloseLink();
    fetch();
  };

  const {
    loading: shareLoading,
    response: shareResponse,
    error: shareError,
    fetch: shareFetch,
  } = useSetShareDevice({
    token,
    userId,
    deviceId,
  });

  const {
    loading: cancelLoading,
    response: cancelResponse,
    error: cancelError,
    fetch: cancelFetch,
  } = useCancelShareDevice({
    token,
    userId,
    deviceId,
    callback: afterCancel,
  });

  const shareHandleCLick = () => {
    onOpenLink();
    shareFetch();
  };

  const revokeHandleCLick = () => {
    cancelFetch();
  };

  return (
    <BoxWrapper noMargin smallRadius>
      <Text ml={4} mr={4} mb={6} fontSize="sm" opacity="0.7">
        You can share your device with family and friends, but control options
        will be limited. You can revoke access to your device at any time.
      </Text>
      {sharedWithCount || activeTokensCount ? (
        <Box mb={8}>
          <ListItem>
            <Flex alignItems="center" justifyContent="space-between">
              <Text>Other users</Text>
              <Text fontWeight="medium">{sharedWithCount}</Text>
            </Flex>
          </ListItem>
          <ListItem last>
            <Flex alignItems="center" justifyContent="space-between">
              <Text>Pending invites</Text>
              <Text fontWeight="medium">{activeTokensCount}</Text>
            </Flex>
          </ListItem>
        </Box>
      ) : null}
      <Box ml={4} mr={4}>
        <Button
          isLoading={shareLoading}
          colorScheme="twitter"
          onClick={shareHandleCLick}
          w="100%"
        >
          Share
        </Button>
        {/* Share link modal */}
        <Modal isOpen={isOpenLink} onClose={handeCloseSharingModal} isCentered>
          <ModalOverlay backdropFilter="blur(1.5px)" />
          <ModalContent mx={4}>
            <ModalHeader>Sharing Link</ModalHeader>
            <ModalCloseButton />
            <ModalBody mb={8}>
              {shareLoading && (
                <Flex justifyContent="center">
                  <Spinner
                    thickness="2px"
                    speed="0.6s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="lg"
                    my={1}
                  />
                </Flex>
              )}
              {!shareLoading && shareResponse.shortLink && (
                <SharingInput link={shareResponse.shortLink} />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
        {sharedWithCount || activeTokensCount ? (
          <>
            <Button
              colorScheme="red"
              variant="ghost"
              mt={2}
              onClick={onOpen}
              w="100%"
            >
              Revoke access
            </Button>
            {/* Revoke access modal */}
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
              <ModalOverlay backdropFilter="blur(1.5px)" />
              <ModalContent mx={4}>
                <ModalHeader>Access revoke</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  Are you sure you want to revoke access to your device from all
                  users?
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    colorScheme="red"
                    isLoading={cancelLoading}
                    onClick={revokeHandleCLick}
                  >
                    Revoke
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        ) : null}
      </Box>
    </BoxWrapper>
  );
};

export default SharingBlock;
