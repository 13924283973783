import React from 'react'
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Heading,
  Box,
  Flex,
  Badge,
  IconButton,
} from "@chakra-ui/react";
import { getOnlineStatus } from 'utils';
import { DeviceType } from 'duck';
import { AiOutlineSetting, AiOutlineReload } from "react-icons/ai";

interface HeadProps {
  data: DeviceType | undefined;
  refetchClick: Function;
}

const Head: React.FC<HeadProps> = ({ data, refetchClick }) => {
  const onlineStatus = React.useMemo(() => getOnlineStatus(data), [data]);
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      mb={{ base: 0, md: 4 }}
    >
      <Flex alignItems="center">
        <Flex flexDirection={{ base: "column", md: "row" }}>
          {/* Badges */}
          <Flex alignItems="center" order={{ md: 1 }} mb={{ base: 1, md: 0 }}>
            {onlineStatus && (
              <Badge
                variant="outline"
                mr={1}
                colorScheme={onlineStatus ? "green" : "red"}
                borderRadius="10"
                px="2"
              >
                {onlineStatus ? "Online" : "Offline"}
              </Badge>
            )}
            {/* Shared badge */}
            {data?.mode && (
              <Badge
                colorScheme="purple"
                height="fit-content"
                variant="outline"
                borderRadius="10"
                px="2"
              >
                {data.mode === "Master" ? "My device" : "Shared"}
              </Badge>
            )}
          </Flex>
          <Heading
            fontSize={{ base: "2xl", md: "3xl" }}
            mr={6}
            noOfLines={1}
            order={{ md: 0 }}
          >
            {data?.name}
          </Heading>
        </Flex>
      </Flex>
      <Flex>
        <IconButton
          onClick={() => refetchClick()}
          icon={<AiOutlineReload />}
          aria-label="Refetc"
          size="md"
          borderRadius="100%"
          mr={2}
        />
        <IconButton
          as={ReactRouterLink}
          icon={<AiOutlineSetting />}
          to={`../../../devices/${data?.deviceId}/settings`}
          aria-label="Device settings"
          size="md"
          borderRadius="100%"
        />
      </Flex>
    </Flex>
  );
};

export default Head