import React from "react";
import { Box, Flex, Text, Link, useColorMode } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";


type ListItemProps = {
  text: string;
  to: string;
  router?: boolean;
  last?: boolean;
};

const ListItem: React.FC<ListItemProps> = ({ text, to, router, last }) => {
  const { colorMode } = useColorMode();
  const getBackgroundColor = () => {
    if (colorMode === "light") {
      return "gray.100";
    }
    if (colorMode === "dark") {
      return "whiteAlpha.200";
    }
  };
  return (
    <>
      <Link
        as={router ? ReactRouterLink : Link}
        to={to}
        href={to}
        textDecoration="none"
        _hover={{ textDecoration: "none", bgColor: getBackgroundColor() }}
        px={4}
        py={3}
        display="block"
        isExternal={!router ? true : false}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Text as="span">{text}</Text>
          <ChevronRightIcon fontSize="xl" />
        </Flex>
      </Link>
      <Box
        ml={4}
        borderBottom={!last ? "1px" : "none"}
        borderColor={colorMode === "dark" ? "whiteAlpha.200" : "gray.200"}
      ></Box>
    </>
    // <Box
    //   ml="4"
    //   pr="4"
    //   width="auto"
    //   pb={!last ? 4 : 0}
    //   mb={!last ? 3 : 0}
    //   borderBottom={!last ? "1px" : "none"}
    //   borderColor={colorMode === "dark" ? "whiteAlpha.200" : "gray.100"}
    // >

    // </Box>
  );
};

export default ListItem;
