import { useState, useEffect } from "react";
import axios from "axios";

const useAuth = (token: any) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_URL;

  const fetchData = () => {
    axios({
      method: "get",
      url: `${baseUrl}/connect/userinfo`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // custom hook returns value
  return { response, error, loading };
};

export default useAuth;
