import { useState, useEffect } from "react";
import axios from "axios";


const useShareAccept = (
  token: string | null | undefined,
  userId: string | undefined,
  sharingToken: string,
  callback: Function,
) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(false);

  const baseUrl = process.env.REACT_APP_API_URL;

  const fetch = () => {
    setloading(true)
    axios({
      method: "post",
      url: `${baseUrl}/api/devices/${userId}/share/accept`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        token: sharingToken,
      },
    })
      .then((res) => {
        setResponse(res.data);
        callback();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  // custom hook returns value
  return { response, error, loading, fetch };
};

export default useShareAccept;
