import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "components";
import { App } from "./App";

const AppProvider: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default AppProvider;
