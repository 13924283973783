import dayjs, { Dayjs } from "dayjs";
import { DeviceType } from "duck";
import { barColors } from "duck";

export const setToLocalStorage = (key: string, data: string) => {
  localStorage.removeItem(key);
  localStorage.setItem(key, data);
};

export const getFromLocalStorage = (key: string | null) => {
  if (key) {
    if (localStorage.getItem(key)) return localStorage.getItem(key);
  }
  return null;
};

export const removeFromLocalStorage = (key: string) => {
  if (key) {
    return localStorage.removeItem(key);
  }
  return null;
};

export const getOnlineStatus = (data: DeviceType | undefined) => {
  if (data) {
    let today = new Date().toISOString();
    let todaySeconds = Date.parse(today) / 1000;
    let deviceDateSeconds = Date.parse(data.lastOnlineClient) / 1000;

    if (data.lastOnlineClient === null) return false;
    if (todaySeconds - deviceDateSeconds < 60) return true;
  }
  return false;
};

export const getTempValue = (value: number, units: string | undefined) => {
  if (units && units === "fahrenheit") {
    return (value * 1.8 + 32).toFixed(1);
  }
  return value.toFixed(1);
};

export const getScaledTime = (scale: any, period: number) => {
  let tillTime;
  let tillTimeDayjs;
  let sinceTime;
  let sinceTimeDayjs;

  if (period) {
    if (period === -1) {
      tillTime = dayjs().startOf(scale).format();
      tillTimeDayjs = dayjs().startOf(scale);
    } else {
      tillTime = dayjs()
        .subtract(period * -1 - 1, scale)
        .startOf(scale)
        .format();
      tillTimeDayjs = dayjs()
        .subtract(period * -1 - 1, scale)
        .startOf(scale);
    }

    sinceTime = dayjs()
      .subtract(period * -1, scale)
      .startOf(scale)
      .format();
    sinceTimeDayjs = dayjs()
      .subtract(period * -1, scale)
      .startOf(scale);
  } else {
    tillTime = dayjs().utc().format();
    tillTimeDayjs = dayjs().utc();
    sinceTime = dayjs()
      .utc()
      .subtract((period - 1) * -1, scale)
      .format();
    sinceTimeDayjs = dayjs()
      .utc()
      .subtract((period - 1) * -1, scale);
  }

  return { tillTime, sinceTime, tillTimeDayjs, sinceTimeDayjs };
};

export const getXAxisTime = (value: string, scale: string) => {
  if (scale === "day") return dayjs(value).local().format("H:mm");
  if (scale === "week") return dayjs(value).local().format("MM/D");
  return dayjs(value).local().format("H:mm");
};

export const getTooltipTime = (value: string, scale: string) => {
  if (scale === "hour") return dayjs(value).local().format("H:mm");
  if (scale === "day" || scale === "week")
    return `${dayjs(value).local().format("MMMM D")} ${dayjs(value)
      .local()
      .format("H:mm")}`;
};

export const getPeriodTimeString = (
  till: Dayjs,
  since: Dayjs,
  scale: string,
  period?: number
) => {
  if (scale === "hour")
    return `${dayjs(since).local().format("MMMM DD")} ${dayjs(since)
      .local()
      .format("HH:mm")} - ${dayjs(till).local().format("HH:mm")}`;
  if (scale === "day") {
    if(period === 0) {
      return `${dayjs(till)
        .local()
        .format("MMMM DD")}`;
    }
    return dayjs(since).local().format("MMMM DD")
  }
  if (scale === "week")
    return `${dayjs(since).local().format("MMMM D")} - ${dayjs(till)
      .local()
      .format("MMMM D")}`;
};

export const getDataArrayByMetric = (
  data: [],
  metric: string,
  theme?: string | null | undefined,
  tempUnits?: string
) => {
  let dataset: any = [];
  for (let index = data.length - 1; index >= 0; index--) {
    const element: any = data[index];
    const getValue = () => {
      if (metric === "temp" || metric === "hum") {
        return getTempValue(element.values[metric], tempUnits);
      }
      if (metric === "hum") return element.values[metric].toFixed(1);
      return element.values[metric];
    };
    let item;
    if (metric === "co2") {
      item = {
        date: element.date,
        value: getValue(),
        valueShow: element.values.co2 < 400 ? 400 : element.values.co2,
        color: getBarColor(getValue(), theme),
      };
    } else {
      item = {
        date: element.date,
        value: getValue(),
      };
    }

    dataset.push(item);
  }
  return dataset;
};

export const getThemeByApp = (theme: string | null, colorMode: string) => {
  if (theme && theme === "app") {
    if (colorMode === "dark") return "yellow";
    if (colorMode === "light") return "purple";
  }
  return theme;
};

export const getBarColor = (ppm: number, theme: string | undefined | null) => {
  let colorIndex = Math.ceil(ppm / 18 - 20);
  if (colorIndex < 0) colorIndex = 0;
  if (colorIndex > 63) colorIndex = 63;
  if (theme) return `#${barColors[theme][colorIndex].toString(16)}`;
};

export const getCo2Level = (ppm: number) => {
  if (ppm < 800) return "Optimal level";
  if (ppm < 1200) return "Acceptable level";
  if (ppm >= 1200) return "High level";
  return "Optimal level";
};
