import React from "react";
import {
  Flex,
  IconButton,
  useColorMode,
  useBreakpointValue,
} from "@chakra-ui/react";
import { NavItem } from "./components";
import { useLocation } from "react-router-dom";
import {
  AiOutlineSetting,
  AiOutlineUnorderedList,
  AiOutlineRight,
  AiOutlineBarChart,
  AiOutlineUser,
} from "react-icons/ai";



function Sidebar() {
  const { colorMode } = useColorMode();
  const navSizeValue = useBreakpointValue(
    {
      base: "small",
      lg: "large",
    },
    {
      fallback: "base",
    }
  );
  const [navSize, setNavSize] = React.useState(navSizeValue);
  const location = useLocation();
  const handleNavSize = () => {
    navSize === "small" && setNavSize("large");
    navSize === "large" && setNavSize("small");
  };

  React.useEffect(() => {
    setNavSize(navSizeValue);
  }, [navSizeValue]);

  return (
    <Flex
      position={{ base: "fixed", md: "relative" }}
      bottom={0}
      left={0}
      right={0}
      p={{ base: 4, lg: 6 }}
      zIndex="11"
      background={colorMode === "light" ? {base: "white", md: "none"} : {base: "gray.800", md: "none"}}
    >
      <Flex
        flex={1}
        position="relative"
        pl={navSize === "small" ? { base: 0, md: "2" } : "6"}
        pt={{ base: 0, md: 3 }}
        pb={{ base: 1, md: 3 }}
        backgroundColor={colorMode === "light" ? "gray.100" : "whiteAlpha.200"}
        borderRadius="25"
        w={{ base: "auto", md: "fit-content" }}
        minW={navSize === "small" ? "70px" : "250px"}
        direction={{ base: "row", md: "column" }}
        justifyContent={{ base: "center", md: "space-between" }}
      >
        <Flex direction={{ base: "row", md: "column" }}>
          <IconButton
            display={{ base: "none", lg: "flex" }}
            aria-label="Navbar"
            icon={<AiOutlineRight />}
            onClick={handleNavSize}
            position="absolute"
            top="4"
            right="-20px"
            zIndex="11"
            size="md"
            borderRadius="100%"
            backgroundColor="primary.500"
            _hover={{ backgroundColor: "primary.400" }}
            _active={{ backgroundColor: "primary.400" }}
            color="white"
            transform={navSize === "small" ? "rotate(0deg)" : "rotate(180deg)"}
          />
          <Flex
            direction={{ base: "row", md: "column" }}
            mt={{ base: 0, md: 16 }}
          >
            <NavItem
              to="/charts"
              navSize={navSize}
              icon={<AiOutlineBarChart />}
              title="Charts"
              isActive={location.pathname.includes("charts")}
            />
            <NavItem
              to="/devices"
              navSize={navSize}
              icon={<AiOutlineUnorderedList />}
              title="My Devices"
              isActive={location.pathname.includes("devices")}
            />
            <NavItem
              to="/app-settings"
              navSize={navSize}
              icon={<AiOutlineSetting />}
              title="App settings"
              isActive={
                location.pathname.includes("app-settings") &&
                !location.pathname.includes("account")
              }
            />
          </Flex>
        </Flex>
        <Flex direction="column" alignItems="start" mb={{ base: 0, md: 9 }}>
          <NavItem
            to="/app-settings/account"
            navSize={navSize}
            icon={<AiOutlineUser />}
            title="User account"
            isActive={location.pathname.includes("app-settings/account")}
          />
          {/* <Link
          as={ReactRouterLink}
          to="/app-settings/account"
          textDecoration="none"
          _hover={{ textDecoration: "none" }}
        >
          <Flex alignItems="center" mt={4} mb={2}>
            <IconButton
              icon={<AiOutlineUser />}
              aria-label="User setting"
              mr={0}
              ml={2}
              fontSize="lg"
              size={navSize === "small" ? "md" : "md"}
              borderRadius="100%"
              backgroundColor="white"
              _hover={{ backgroundColor: "white" }}
            />
            <Flex
              direction="column"
              ml={2}
              display={navSize === "small" ? "none" : "flex"}
            >
              <Text
                fontWeight={600}
                fontSize="1rem"
                lineHeight="1.1"
                noOfLines={2}
              >
                {auth.user?.name}
              </Text>
              {!auth.user?.email.includes("privaterelay") ? (
                <Text fontSize="0.75rem" color="gray.500">
                  {auth.user?.email}
                </Text>
              ) : null}
            </Flex>
          </Flex>
        </Link> */}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Sidebar;
