import {
  Box,
  Heading,
  IconButton,
  Flex,
  Button,
  Text,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorMode,
} from "@chakra-ui/react";
import { BoxWrapper, ListItem } from "components/ui";
import { ResponseError } from "components"
import { useAuth } from "hooks";
import { useUserDevices, useRemoveUser } from "api";
import { useNavigate } from "react-router-dom";
import { DevicesResponseType, DeleteAccountType } from "duck";
import {
  AiOutlineLeft,
  AiOutlineLogout,
} from "react-icons/ai";

function AccountSettings() {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const { auth, setAuth } = useAuth();
  const token = auth.token;
  const userId = auth.user?.sub;

  const signOut = () => {
    localStorage.removeItem("token");
    setAuth({ isAuthenticating: false, isLoggedIn: false, user: null });
  };

  const { loading, response, error, fetch }: DevicesResponseType = useUserDevices(
      auth.token,
      auth.user?.sub
  );
  const {
    loading: loadingRemove,
    error: errorRemove,
    fetch: fetchRemove,
  }: DeleteAccountType = useRemoveUser({ token, userId, callback: signOut });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  
  const handleSignOut = () => {
    signOut()
  };

  const handleDeleteAccount = () => {
    fetchRemove();
  };

  return (
    <Flex flexDirection="column" h="100%">
      <Flex alignItems="center" mb="6">
        <IconButton
          icon={<AiOutlineLeft />}
          aria-label="Back to"
          size="md"
          mr="2"
          borderRadius="100%"
          onClick={() => navigate(-1)}
        />
        <Heading fontSize={{ base: "2xl", md: "3xl" }}>Account</Heading>
      </Flex>
      {loading && (
        <Box maxW={{ md: "600px" }}>
          <Skeleton height="225px" mb="4" isLoaded={!loading} />
          <Skeleton height="40px" width="300px" isLoaded={!loading} />
        </Box>
      )}
      {!loading && error && <ResponseError reload={fetch} />}
      {!loading && !error && response && (
        <Box maxW={{ md: "600px" }}>
          <BoxWrapper smallRadius>
            <ListItem>
              <>
                <Text
                  fontWeight="600"
                  fontSize="sm"
                  color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
                >
                  User name
                </Text>
                <Text fontSize="md">{auth.user?.name}</Text>
              </>
            </ListItem>
            <ListItem>
              <>
                <Text
                  fontWeight="600"
                  fontSize="sm"
                  color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
                >
                  User email
                </Text>
                <Text fontSize="md">
                  {!auth.user?.email.includes("privaterelay")
                    ? auth.user?.email
                    : "hidden Apple email"}
                </Text>
              </>
            </ListItem>
            <ListItem last>
              <Text
                fontWeight="600"
                fontSize="sm"
                color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
              >
                Total devices
              </Text>
              <Text fontSize="md">{response.length}</Text>
            </ListItem>
          </BoxWrapper>
          <Flex>
            <Button
              leftIcon={<AiOutlineLogout />}
              colorScheme="red"
              onClick={onOpen}
              mr={3}
            >
              Sign out
            </Button>
            <Button colorScheme="red" variant="ghost" onClick={onOpenDelete}>
              Delete account
            </Button>
          </Flex>

          {/* Modals */}
          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay backdropFilter="blur(1.5px)" />
            <ModalContent mx={4}>
              <ModalHeader>Sign out</ModalHeader>
              <ModalCloseButton />
              <ModalBody>Do you realy want to sign out?</ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Stay logged in
                </Button>
                <Button
                  colorScheme="red"
                  variant="ghost"
                  onClick={handleSignOut}
                >
                  Sign out
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal isOpen={isOpenDelete} onClose={onCloseDelete} isCentered>
            <ModalOverlay backdropFilter="blur(1.5px)" />
            <ModalContent mx={4}>
              <ModalHeader>Delete account</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                Deleting the account will result in loss of all devices and data
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onCloseDelete}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  variant="ghost"
                  onClick={handleDeleteAccount}
                  isLoading={loadingRemove}
                >
                  Continue
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      )}
    </Flex>
  );
}

export default AccountSettings;
