import React, { useState } from "react";
import dayjs from "dayjs";
import {
  Box,
  Flex,
  Heading,
  Skeleton,
  Grid,
  Text,
  CircularProgress,
  CircularProgressLabel,
  IconButton,
  useColorMode,
} from "@chakra-ui/react";
import { DeviceItem } from "./components";
import { useUserDevices } from "api";
import { useAuth, useAppSettings, useTabActive } from "hooks";
import { DevicesResponseType, DeviceType } from "duck";
import { getFromLocalStorage, setToLocalStorage } from "utils";
import { AiOutlineReload } from "react-icons/ai";
import { BoxWrapper } from "components/ui";
import { NoDevices, ResponseError } from "components";

const DevicesList = () => {
  const { colorMode } = useColorMode();
  const tabActive = useTabActive();
  const [reloadSeconds, setReloadSeconds] = React.useState(60);
  const { auth } = useAuth();
  const { tempUnitsValue } = useAppSettings();
  const { loading, response, error, fetch }: DevicesResponseType =
    useUserDevices(auth.token, auth.user?.sub);
  const [activeDeviceId, setActiveDeviceId] = useState(
    getFromLocalStorage("activeDeviceId") ?? ""
  );
  const date = dayjs(response[0]?.lastOnlineServer)
    .local()
    .format("MMMM DD HH:mm");
  const refetchClick = () => {
    fetch();
    setReloadSeconds(60);
  };
  React.useEffect(() => {
    if (!getFromLocalStorage("activeDeviceId")) {
      if (response && response.length > 0) {
        const firstDeviceId = response[0].deviceId;
        setToLocalStorage("activeDeviceId", firstDeviceId);
        setActiveDeviceId(firstDeviceId);
      }
    }
  }, [response]);

  React.useEffect(() => {
    const intervalSec = setInterval(() => {
      if (tabActive && reloadSeconds > 0) {
        setReloadSeconds((reloadSeconds) => reloadSeconds - 1);
      }
      if (tabActive && reloadSeconds === 0) {
        setReloadSeconds(60);
        fetch();
      }
    }, 1000);
    return () => {
      clearInterval(intervalSec);
    };
  }, [tabActive, fetch, reloadSeconds]);

  return (
    <Flex flexDirection="column" h="100%">
      <Flex alignItems="center" justifyContent="space-between" mb="4">
        <Heading fontSize={{ base: "2xl", md: "3xl" }}>My Devices</Heading>
        <Box>
          <IconButton
            onClick={() => refetchClick()}
            icon={<AiOutlineReload />}
            aria-label="Refetc"
            size="md"
            borderRadius="100%"
          />
        </Box>
      </Flex>

      {loading && (
        <Box maxW={{ md: "600px" }}>
          <Skeleton height="130px" isLoaded={!loading} />
        </Box>
      )}
      {!loading && error && <ResponseError reload={fetch} />}
      {!loading && !error && response.length === 0 && <NoDevices />}
      {!loading && response.length > 0 && (
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Box w="100%" maxW={{ md: "600px" }}>
            <BoxWrapper noPadding smallRadius>
              <Grid templateColumns="repeat(1, 1fr)" gap={4} py={{base: 3, md: 4}}>
                {response.map((device: DeviceType) => (
                  <DeviceItem
                    key={device.deviceId}
                    data={device}
                    tempUnitsValue={tempUnitsValue}
                    activeDeviceId={activeDeviceId}
                    setActiveDeviceId={setActiveDeviceId}
                  />
                ))}
              </Grid>
            </BoxWrapper>
          </Box>
          <Flex
            flexDirection={{ base: "row", md: "column" }}
            alignItems={{ base: "center", md: "end" }}
            justifyContent={{ base: "end", md: "start" }}
            mb={{ base: 4, md: 0 }}
          >
            <Box>
              <Text
                fontSize="sm"
                fontWeight="500"
                align="right"
                color={
                  colorMode === "dark" ? "rgba(255,255,255, 0.64)" : "#718096"
                }
              >
                Last update:
              </Text>
              <Text
                fontSize="sm"
                fontWeight="500"
                textAlign="right"
                lineHeight="1.1"
              >
                {date}
              </Text>
            </Box>

            <CircularProgress
              value={100 - Math.round((100 / 60) * reloadSeconds)}
              color={colorMode === "dark" ? "secondary.500" : "primary.500"}
              trackColor={colorMode === "dark" ? "whiteAlpha.300" : "gray.200"}
              size="2.5rem"
              mt={{ base: 0, md: 2 }}
              ml={{ base: 2, md: 0 }}
            >
              <CircularProgressLabel>{reloadSeconds}s</CircularProgressLabel>
            </CircularProgress>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default DevicesList;
