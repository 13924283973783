import axios from "axios";
import { useState } from "react";
  

const useSetDeviceInfo = (
  token: string | null | undefined,
  userId: string | undefined,
  deviceId: string | undefined,
) => {
  const baseUrl = process.env.REACT_APP_API_URL;

  const [response, setResponse] = useState();
  const [error, setError] = useState("");
  const [loading, setloading] = useState(true);

  const fetch = (data: Object) => {
    axios({
      method: "put",
      url: `${baseUrl}/api/devices/${userId}/${deviceId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: deviceId,
        userId: userId,
        type: "Set device info data",
        ...data,
      },
    })
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  return { response, error, loading, fetch };

};

export default useSetDeviceInfo;
