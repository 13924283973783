import { Box, Text, useColorMode, useBreakpointValue } from "@chakra-ui/react";
import { BoxWrapper } from "components/ui";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  getDataArrayByMetric,
  getFromLocalStorage,
  getXAxisTime,
  getTooltipTime,
  getThemeByApp,
} from "utils";
import { useAppSettings } from "hooks";

interface TemperatureProps {
  data: [];
  scale: string;
}

const Temperature = ({ data, scale }: TemperatureProps) => {
  const { colorMode } = useColorMode();
  const theme = getThemeByApp(
    getFromLocalStorage("chartsScheme") || "app",
    colorMode
  );
  const textColor =
    colorMode === "dark" ? "rgba(255,255,255, 0.64)" : "#718096";
  const { tempUnitsValue } = useAppSettings();
  const dataset = getDataArrayByMetric(data, "temp", scale, tempUnitsValue);
  const formatXAxis = (value: string, index: number) => {
    return getXAxisTime(value, scale);
  };
  const fontSizeValue = useBreakpointValue(
    {
      base: "10px",
      lg: "12px",
    },
    {
      fallback: "base",
    }
  );
  return (
    <Box width="100%">
      <BoxWrapper noMargin noPadding smallRadius>
        <Box
          pl={{ base: 3, md: 4 }}
          py={{ base: 2, md: 3 }}
          pr={{ base: 0, md: 0 }}
        >
          <Text
            fontWeight="600"
            fontSize={{ base: "xs", md: "sm" }}
            color={textColor}
          >
            Temperature
          </Text>

          <Box width="100%" height={{ base: "100px", md: "110px" }}>
            <ResponsiveContainer width="99%" height="100%">
              <AreaChart
                width={600}
                height={110}
                data={dataset}
                margin={{ top: 11, right: 0, left: 0, bottom: 0 }}
                syncId="charts"
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={theme === "yellow" ? "#e9cd9f" : "#9461ed"}
                      stopOpacity={0.6}
                    />
                    <stop
                      offset="95%"
                      stopColor={theme === "yellow" ? "#e9cd9f" : "#9461ed"}
                      stopOpacity={0}
                    />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={theme === "yellow" ? "#e9cd9f" : "#9461ed"}
                      stopOpacity={0.6}
                    />
                    <stop
                      offset="95%"
                      stopColor={theme === "yellow" ? "#e9cd9f" : "#9461ed"}
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="date"
                  axisLine={false}
                  tickSize={0}
                  tick={{ fontSize: fontSizeValue, fill: textColor }}
                  tickCount={0}
                  height={20}
                  tickMargin={10}
                  padding={{ left: 0, right: 4 }}
                  tickFormatter={formatXAxis}
                />
                <YAxis
                  orientation="right"
                  axisLine={false}
                  tickSize={0}
                  tick={{ fontSize: fontSizeValue, fill: textColor }}
                  tickMargin={2}
                  width={32}
                  allowDecimals={false}
                  domain={[
                    (dataMin: number) => Math.ceil(dataMin) - 3,
                    (dataMax: number) => Math.ceil(dataMax),
                  ]}
                  type="number"
                />
                <Tooltip
                  content={<CustomTooltip scale={scale} />}
                  cursor={{ opacity: 0.2 }}
                  allowEscapeViewBox={{ x: false, y: false }}
                  position={{ y: 0 }}
                />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke={theme === "yellow" ? "#e9cd9f" : "#9461ed"}
                  fillOpacity={1}
                  fill="url(#colorUv)"
                  isAnimationActive={true}
                  animationDuration={500}
                />
              </AreaChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </BoxWrapper>
    </Box>
  );
};

const CustomTooltip = ({ active, payload, label, scale }: any) => {
  const { tempUnitsValue } = useAppSettings();
  if (active && payload && payload.length) {
    return (
      <Box>
        <Text fontSize="md" fontWeight="600" lineHeight="1.1">
          {getTooltipTime(label, scale)}
        </Text>
        <Text fontSize="xl" fontWeight="800" lineHeight="1.1">
          {payload[0].value}{" "}
          <Text as="span" fontSize="sm">
            {tempUnitsValue === "celsius" ? "°С" : "°F"}
          </Text>
        </Text>
      </Box>
    );
  }

  return null;
};

export default Temperature;
