import { useState, useReducer } from "react";
import axios from "axios";

// An interface for our actions
interface ReducerAction {
  type: string;
  payload: any;
}

// An interface for our state
interface ReducerState {
  co2: any;
  temp: any;
  hum: any;
}
// function prepareCo2Data(arr: Array<{}>) {
//   let newArr: Array<{}> = [];
//   arr.forEach((element: { [key: string]: any }) => {
//     if (element.values.co2 < 400) {
//       element.values.co2show = 400;
//     } else {
//       element.values.co2show = element.values.co2;
//     }
//     newArr.push(element);
//   });
//   return newArr;
// }

function reducer(state: ReducerState, action: ReducerAction) {
  switch (action.type) {
    case "co2":
      return { ...state, co2: action.payload };
    case "temp":
      return { ...state, temp: action.payload };
    case "hum":
      return { ...state, hum: action.payload };
    default:
      throw new Error();
  }
}

const useDeviceLastData = (
  token: string | null | undefined,
  userId: string | undefined,
  deviceId: string | undefined
) => {
  const initialState = { co2: [], temp: [], hum: [] };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const baseUrl = process.env.REACT_APP_API_URL;

  const getScale = (val: string) => {
    if (val === "hour") return "00:01:00";
    if (val === "day") return "00:24:00";
    if (val === "week") return "02:48:00";
    return "00:01:00";
  };

  const fetchMetrik = (
    since: string,
    till: string,
    step: string,
    metric: string
  ) =>
    axios({
      method: "get",
      url: `${baseUrl}/api/data/${userId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        deviceid: deviceId,
        since: since,
        till: till,
        metrictype: metric,
        step: step,
      },
    });

  const fetch = (since: string, till: string, scale: string) => {
    setLoading(true);
    const step = getScale(scale);
    Promise.allSettled([
      fetchMetrik(since, till, step, "co2"),
      fetchMetrik(since, till, step, "temp"),
      fetchMetrik(since, till, step, "hum"),
    ]).then((res) => {
      res.forEach((item: any) => {
        if (item.value.data.length > 0) {
          const key = Object.keys(item.value?.data[0].values)[0];
          dispatch({ type: key, payload: item.value?.data });
        }
      });
      setLoading(false);
    });
  };

  // custom hook returns value
  return { state, error, loading, fetch };
};

export default useDeviceLastData;
