import { getFromLocalStorage } from "utils";

const useAppSettings = () => {
  const tempUnitsValue = getFromLocalStorage("tempUnits") || "celsius";
  const chartsSchemeValue = getFromLocalStorage("chartsScheme") || "app";
  const weekStartsValue = getFromLocalStorage("weekStarts") || "monday";

  return { tempUnitsValue, chartsSchemeValue, weekStartsValue };
};

export default useAppSettings;
