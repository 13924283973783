import { Route, Routes } from "react-router-dom";
import { DevicesList, DeviceSettings } from "./components";

function Devices() {
  return (
    <Routes>
      <Route path="/" element={<DevicesList />} />
      <Route path="/:deviceId/settings" element={<DeviceSettings />} />
    </Routes>
  );
}

export default Devices;
