import React from "react";
import {
  FormControl,
  FormLabel,
  Switch,
  Flex,
  Input,
  useColorMode,
} from "@chakra-ui/react";
import { useSetDeviceSetting } from "api";
import { ListItem } from "components/ui";

type SilentHoursBlockProps = {
  token: string | null | undefined;
  userId: string | undefined;
  deviceId: string | undefined;
  alias: string;
  data: string;
};

const SilentHoursBlock = ({
  token,
  userId,
  deviceId,
  alias,
  data,
}: SilentHoursBlockProps) => {
  const { colorMode } = useColorMode();
  const shStatus = JSON.parse(data).enabled;
  const shSince = JSON.parse(data).since;
  const shTill = JSON.parse(data).till;
  const localDate = new Date();
  const timezone = localDate.getTimezoneOffset() * 60;
  const [active, setActive] = React.useState(shStatus);
  const [state, setState] = React.useState(JSON.parse(data));

  function getSecondsWithTimezone(sec: number) {
    return sec + timezone * -1;
  }

  function getSecondsUtc(sec: number) {
    return sec + timezone;
  }

  function getTimefromSeconds(sec: number) {
    const date = new Date(0);
    date.setSeconds(sec);
    var time = date.toISOString().substring(11, 16);
    return time;
  }

  function getSecondsFromTime(time: string) {
    const a = time.split(":");
    const seconds = +a[0] * 60 * 60 + +a[1] * 60;
    return seconds;
  }

  // TODO add loading and error check
  const { fetch } = useSetDeviceSetting(token, userId, deviceId);

  const handleInputChange = (e: boolean) => {
    setState({ ...state, enabled: e });
    fetch({ alias: alias, data: { ...state, enabled: e } });
    setActive(!active)
  };

  const handleSinceInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const seconds = getSecondsUtc(getSecondsFromTime(e.target.value));
    setState({ ...state, since: seconds });
    fetch({ alias: alias, data: { ...state, since: seconds } });
  };

  const handleTillInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const seconds = getSecondsUtc(getSecondsFromTime(e.target.value));
    setState({ ...state, till: seconds });
    fetch({ alias: alias, data: { ...state, till: seconds } });
  };

  return (
    <ListItem>
      <FormControl>
        <Flex
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="4"
        >
          <FormLabel
            htmlFor="led-status"
            mb="0"
            fontSize="sm"
            color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
          >
            LED sleep mode
          </FormLabel>
          <Switch
            id="led-status"
            size="lg"
            colorScheme="blue"
            defaultChecked={shStatus}
            onChange={(e) => handleInputChange(e.target.checked)}
          />
        </Flex>
        <Flex ml="6" mb="2" alignItems="center">
          <FormLabel
            htmlFor="since"
            mb="0"
            mr="4"
            minW="40px"
            fontSize="sm"
            color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
          >
            From
          </FormLabel>
          <Input
            id="since"
            placeholder="Select Date and Time"
            variant="filled"
            size="md"
            type="time"
            defaultValue={getTimefromSeconds(getSecondsWithTimezone(shSince))}
            onBlur={(e) => handleSinceInputChange(e)}
            disabled={!active}
          />
        </Flex>
        <Flex ml="6" mb="2" alignItems="center">
          <FormLabel
            htmlFor="till"
            mb="0"
            mr="4"
            minW="40px"
            fontSize="sm"
            color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
          >
            To
          </FormLabel>
          <Input
            id="till"
            placeholder="Select Date and Time"
            variant="filled"
            size="md"
            type="time"
            defaultValue={getTimefromSeconds(getSecondsWithTimezone(shTill))}
            onBlur={(e) => handleTillInputChange(e)}
            disabled={!active}
          />
        </Flex>
      </FormControl>
    </ListItem>
  );
};

export default SilentHoursBlock;
