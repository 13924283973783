import {
  Box,
  Heading,
  IconButton,
  Flex,
  FormControl,
  FormLabel,
  Select,
  useColorMode,
} from "@chakra-ui/react";
import { BoxWrapper, ListItem, LinkItem } from "components/ui";
import { setToLocalStorage } from "utils";
import { useAppSettings } from "hooks";
import { tempUnits, chartsScheme, appTheme } from "duck/constants";
import { useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";

function MainSettings() {
  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();
  const handleAppTheme = (e: string) => {
    toggleColorMode();
  };
  const { tempUnitsValue, chartsSchemeValue } =
    useAppSettings();

  return (
    <Box>
      <Flex alignItems="center" mb="6">
        <IconButton
          icon={<AiOutlineLeft />}
          aria-label="Back to"
          size="md"
          mr="2"
          borderRadius="100%"
          onClick={() => navigate(-1)}
        />
        <Heading fontSize={{ base: "2xl", md: "3xl" }}>App Settings</Heading>
      </Flex>
      <Box maxW={{ md: "600px" }}>
        <BoxWrapper smallRadius>
          <ListItem>
            <FormControl>
              <FormLabel
                htmlFor="tempUnits"
                mb="1"
                fontSize="sm"
                color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
              >
                Temperature units
              </FormLabel>
              <Select
                variant="filled"
                id="tempUnits"
                defaultValue={tempUnitsValue}
                onChange={(e) => setToLocalStorage("tempUnits", e.target.value)}
              >
                {tempUnits.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.key}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl>
              <FormLabel
                htmlFor="chartsScheme"
                mb="1"
                fontSize="sm"
                color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
              >
                Charts Color Scheme
              </FormLabel>
              <Select
                variant="filled"
                id="chartsScheme"
                defaultValue={chartsSchemeValue}
                onChange={(e) =>
                  setToLocalStorage("chartsScheme", e.target.value)
                }
              >
                {chartsScheme.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.key}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem last>
            <FormControl>
              <FormLabel
                htmlFor="appTheme"
                mb="1"
                fontSize="sm"
                color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
              >
                Application theme
              </FormLabel>
              <Select
                variant="filled"
                id="appTheme"
                defaultValue={colorMode}
                onChange={(e) => handleAppTheme(e.target.value)}
              >
                {appTheme.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.key}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ListItem>
          {/* <ListItem last>
            <FormControl>
              <FormLabel htmlFor="weekStarts" mb="1" fontSize="sm">
                Week Starts With
              </FormLabel>
              <Select
                variant="filled"
                id="weekStarts"
                defaultValue={weekStartsValue}
                onChange={(e) =>
                  setToLocalStorage("weekStarts", e.target.value)
                }
              >
                {weekStarts.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.key}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ListItem> */}
        </BoxWrapper>
        <BoxWrapper noPadding smallRadius>
          <LinkItem text="Account" to="/app-settings/account" router last />
        </BoxWrapper>
        <BoxWrapper noPadding smallRadius>
          <LinkItem
            text="Help and Support"
            to="https://balloonair.tech/support"
          />
          <LinkItem
            text="Privacy Policy"
            to="https://balloonair.tech/legal/app-privacy-policy"
          />
          <LinkItem
            text="Terms of Use"
            to="https://balloonair.tech/legal/app-privacy-policy"
            last
          />
        </BoxWrapper>
      </Box>
    </Box>
  );
}

export default MainSettings;
