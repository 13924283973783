import React from "react";
import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useColorMode,
} from "@chakra-ui/react";
import { useSetDeviceSetting } from "api";
import { ListItem } from "components/ui";

type LedBrightSliderProps = {
  token: string | null | undefined;
  userId: string | undefined;
  deviceId: string | undefined;
  alias: string;
  data: string;
};

const LedBrightSlider = ({
  token,
  userId,
  deviceId,
  alias,
  data,
}: LedBrightSliderProps) => {
  const { colorMode } = useColorMode();
  const ledBrightValue = JSON.parse(data).val;
  const getPercents = (val: number) => {
    return Math.round(val/255*100)
  }
  const [percents, setPercents] = React.useState(
    getPercents(ledBrightValue)
  );

  // TODO add loading and error check
  const { fetch } = useSetDeviceSetting(token, userId, deviceId);

  const handleInputChangeEnd = (e: number) => {
    fetch({ alias: alias, data: { val: e } });
  };

  const handleInputChange = (e: number) => {
    setPercents(getPercents(e));
  };

  return (
    <ListItem>
      <FormControl>
        <Flex justifyContent="space-between">
          <FormLabel
            htmlFor="colorShema"
            mb="0"
            fontSize="sm"
            color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
          >
            LED bright
          </FormLabel>
          <Text as="span" fontSize="sm" fontWeight="600">
            {percents}%
          </Text>
        </Flex>
        <Slider
          defaultValue={ledBrightValue}
          max={255}
          step={1}
          onChangeEnd={(val) => handleInputChangeEnd(val)}
          onChange={(val) => handleInputChange(val)}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb boxSize={4} />
        </Slider>
      </FormControl>
    </ListItem>
  );
};

export default LedBrightSlider;
