import React from 'react'
import { Flex, Input, Button, useClipboard } from "@chakra-ui/react";

interface SharingInputProps {
  link: string
}

const SharingInput = ({link}: SharingInputProps) => {
  const { onCopy, hasCopied } = useClipboard(link);
  return (
    <Flex>
      <Input
        defaultValue={link}
        //value={value}
        mr={2}
      />
      <Button onClick={onCopy}>{hasCopied ? "Copied!" : "Copy"}</Button>
    </Flex>
  );
};

export default SharingInput