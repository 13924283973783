import { Text, useColorMode } from "@chakra-ui/react";
import { DeviceType } from "duck";
import { ListItem, BoxWrapper } from "components/ui";

type AboutBlockProps = {
  data: DeviceType;
};

const AboutBlock = ({ data }: AboutBlockProps) => {
  const { colorMode } = useColorMode();
  const { firmwareInstalled, firmwareInstalledAt, lastOnlineServer } = data;
  const firmwareDate = new Date(firmwareInstalledAt).toLocaleString();
  const lastOnlineDate = new Date(lastOnlineServer).toLocaleString();
  return (
    <BoxWrapper noMargin smallRadius>
      <ListItem>
        <>
          <Text
            fontWeight="medium"
            fontSize="sm"
            color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
          >
            Firmware version
          </Text>
          <Text fontSize="md">{firmwareInstalled}</Text>
        </>
      </ListItem>
      <ListItem>
        <>
          <Text
            fontWeight="medium"
            fontSize="sm"
            color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
          >
            Firmware installation time
          </Text>
          <Text fontSize="md">{firmwareDate}</Text>
        </>
      </ListItem>
      <ListItem last>
        <>
          <Text
            fontWeight="medium"
            fontSize="sm"
            color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
          >
            Last online
          </Text>
          <Text fontSize="md">
            {lastOnlineDate}
          </Text>
        </>
      </ListItem>
    </BoxWrapper>
  );
};

export default AboutBlock