import {
  LedModeSelector,
  SilentHoursBlock,
  LedBrightSlider,
  ColorSchemaSelector,
  Co2LevelAlertSelector,
} from "./components";
import { useAuth } from "hooks";
import { BoxWrapper } from "components/ui";

type MainBlockProps = {
  data: any;
};

const MainBlock = ({ data }: MainBlockProps) => {
  const { auth } = useAuth();
  const ledData = data.filter((x: any) => x.alias === "bair_led_mode")[0];
  const colorSchemaData = data.filter(
    (x: any) => x.alias === "bair_led_color_schema"
  )[0];
  const ledBrightData = data.filter(
    (x: any) => x.alias === "bair_led_max_bright"
  )[0];
  const co2LevelData = data.filter(
    (x: any) => x.alias === "bair_co2_threshold"
  )[0];
  const silentHoursData = data.filter(
      (x: any) => x.alias === "bair_silent_hours"
    )[0];
  
  return (
    <BoxWrapper noMargin smallRadius>
      <LedModeSelector
        token={auth.token}
        userId={auth.user?.sub}
        {...ledData}
      />
      <SilentHoursBlock
        token={auth.token}
        userId={auth.user?.sub}
        {...silentHoursData}
      />
      <LedBrightSlider
        token={auth.token}
        userId={auth.user?.sub}
        {...ledBrightData}
      />
      <ColorSchemaSelector
        token={auth.token}
        userId={auth.user?.sub}
        {...colorSchemaData}
      />
      <Co2LevelAlertSelector
        token={auth.token}
        userId={auth.user?.sub}
        {...co2LevelData}
      />
    </BoxWrapper>
  );
};

export default MainBlock