import {
  extendTheme,
  type ThemeConfig,
  type StyleFunctionProps,
} from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const Colors = {
  primary: {
    50: "#e1e8fb",
    100: "#becef6",
    200: "#9cb4f1",
    300: "#7a9aec",
    400: "#5780e7",
    500: "#4673e5",
    600: "#3566e3",
    700: "#2459e0",
    800: "#1e51d3",
    900: "#1b4ac1",
  },
  blue: {
    50: "#e1e8fb",
    100: "#becef6",
    200: "#9cb4f1",
    300: "#7a9aec",
    400: "#5780e7",
    500: "#4673e5",
    600: "#3566e3",
    700: "#2459e0",
    800: "#1e51d3",
    900: "#1b4ac1",
  },
  secondary: {
    50: "#afecff",
    100: "#88e3ff",
    200: "#61d9ff",
    300: "#39d0ff",
    400: "#00c1fe",
    500: "#00b2ea",
    600: "#00a3d6",
    700: "#0094c3",
    800: "#0085af",
    900: "#00769c",
  },
  bg: {
    50: "#fbfbfb",
    100: "#F6F6F6",
    200: "#e2e2e2",
    300: "#c5c5c5",
    400: "#9e9e9e",
    500: "#8a8a8a",
    600: "#777777",
    700: "#595959",
    800: "#454545",
    900: "#1e1e1e",
  },
  gray: {
    50: "#F7FAFC",
    100: "#f3f4fb",
    200: "#E2E8F0",
    300: "#CBD5E0",
    400: "#A0AEC0",
    500: "#718096",
    600: "#4A5568",
    700: "#2D3748",
    800: "#1A202C",
    900: "#171923",
  },
};

const theme = extendTheme({
  config,
  fonts: {
    heading: `-apple-system, BlinkMacSystemFont, 'Source Sans Pro', sans-serif`,
    body: `-apple-system, BlinkMacSystemFont, 'Source Sans Pro', sans-serif`,
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "1",
          bg: "gray.100",
        },
      },
    }),
  },
  colors: {
    ...Colors,
  },
  components: {
    Modal: {
      baseStyle: {
        dialog: {
          borderRadius: "25px",
        },
        closeButton: {
          top: "3",
          borderRadius: "100%",
          w: "2.5rem",
          height: "2.5rem",
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "500",
        borderRadius: "2.5rem",
      },
      variants: {
        ghost: {
          _hover: {
            bg: "transparent",
          },
          _active: {
            bg: "transparent",
          },
        },
      },
    },
    Select: {
      variants: {
        filled: (props: StyleFunctionProps) => ({
          field: {
            bg: props.colorMode === "dark" ? "whiteAlpha.100" : "white",
            borderRadius: "8",
            _hover: {
              bg: props.colorMode === "dark" ? "whiteAlpha.100" : "white",
              borderColor:
                props.colorMode === "dark" ? "whiteAlpha.200" : "gray.200",
            },
            _focus: {
              bg: props.colorMode === "dark" ? "whiteAlpha.100" : "white",
              borderColor:
                props.colorMode === "dark" ? "whiteAlpha.200" : "gray.200",
            },
          },
        }),
      },
    },
    Input: {
      variants: {
        filled: (props: StyleFunctionProps) => ({
          field: {
            bg: props.colorMode === "dark" ? "whiteAlpha.100" : "white",
            borderRadius: "8",
            _hover: {
              bg: props.colorMode === "dark" ? "whiteAlpha.100" : "white",
              borderColor:
                props.colorMode === "dark" ? "whiteAlpha.200" : "gray.200",
            },
            _focus: {
              bg: props.colorMode === "dark" ? "whiteAlpha.100" : "white",
              borderColor:
                props.colorMode === "dark" ? "whiteAlpha.200" : "gray.200",
            },
          },
        }),
      },
    },
    Skeleton: {
      baseStyle: (props: StyleFunctionProps) => ({
        borderRadius: {base: "15", md: "25"},
      }),
    },
  },
});


export default theme;