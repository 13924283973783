import {
  Flex,
  Text,
  Link,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import AppStoreIcon from "assets/images/appstore.svg";
import GooglePlayIcon from "assets/images/googleplay.svg";

const NoDevices = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex
      flexDirection="column"
      h="100%"
      w="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Text fontSize="lg" mb="4" textAlign="center">
        No BALLOONAIR detected. Please add at least one device.
      </Text>
      <Button leftIcon={<FiPlus />} colorScheme="blue" mb="14" onClick={onOpen}>
        Add device
      </Button>
      <Text fontSize="lg" textAlign="center">
        If you haven't BALLOONAIR{" "}
        <Link href="https://balloonair.tech" textDecor="underline" isExternal>
          get one
        </Link>
        .
      </Text>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay backdropFilter="blur(1.5px)" />
        <ModalContent mx={4}>
          <ModalHeader textAlign="center">Add device</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb="8">
            <Text mb="4" textAlign="center">
              You can add a device using our mobile application. Please download
              it for your mobile device from the links below:
            </Text>
            <Flex justifyContent="center">
              <Link
                href="https://apps.apple.com/us/app/balloonair/id1543427189"
                isExternal
                mr="2"
              >
                <Image src={AppStoreIcon} alt="logo" h="2.5rem" />
              </Link>
              <Link
                href="https://play.google.com/store/apps/details?id=com.balloon.balloonair"
                isExternal
              >
                <Image src={GooglePlayIcon} alt="logo" h="2.5rem" />
              </Link>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default NoDevices;