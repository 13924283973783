import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useRemoveDevice } from "api";
import { AiOutlineDelete } from "react-icons/ai";

type DeleteBlockProps = {
  userId: string | undefined;
  deviceId: string | undefined;
  token: string | null | undefined;
};

const DeleteBlock = ({ userId, deviceId, token }: DeleteBlockProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const afterRemove = () => {
    onClose();
    navigate(-1)
  };
  // TODO set error
  const { loading, fetch } = useRemoveDevice({
    token,
    userId,
    deviceId,
    callback: afterRemove,
  });
  
  const removeHandleClick = () => {
    fetch();
  }

  return (
    <>
      <Box mt={4}>
        <Button
          colorScheme="red"
          leftIcon={<AiOutlineDelete />}
          onClick={onOpen}
        >
          Remove device
        </Button>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay backdropFilter="blur(1.5px)" />
        <ModalContent mx={4}>
          <ModalHeader>Remove device</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Removing the device will result in it's data loss
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="red"
              leftIcon={<AiOutlineDelete />}
              isLoading={loading}
              onClick={removeHandleClick}
            >
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteBlock;
