import {
  Flex,
  Text,
  Button,
} from "@chakra-ui/react";
import { AiOutlineReload } from "react-icons/ai";

interface NoDataProps {
  reload: Function;
} 

const NoData = ({ reload }: NoDataProps) => {
  return (
    <Flex
      flexDirection="column"
      h="100%"
      w="100%"
      alignItems="center"
      justifyContent="center"
      mb="10"
    >
      <Text fontSize="lg" textAlign="center">
        No data!
      </Text>
      <Text fontSize="md" mb="6" textAlign="center" color="gray">
        We are waiting for the first data from the device.
      </Text>
      <Button
        leftIcon={<AiOutlineReload />}
        colorScheme="blue"
        onClick={() => reload()}
      >
        Reload
      </Button>
    </Flex>
  );
};

export default NoData;