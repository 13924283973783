import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Layout, Devices, AppSettings, Charts } from "./components";

const Cabinet: React.FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Navigate to="/charts" />} />
        <Route path="*" element={<Navigate to="/charts" />} />
        <Route path="/devices/*" element={<Devices />} />
        <Route path="/charts/*" element={<Charts />} />
        <Route path="/app-settings/*" element={<AppSettings />} />
      </Route>
    </Routes>
  );
};

export default Cabinet;
