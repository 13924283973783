import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Preloader, NoDevices, SharedDevice } from "components";
import { getFromLocalStorage, setToLocalStorage } from "utils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks";
import { useUserDevices } from "api";
import { DevicesResponseType } from "duck";

const ChartsRoot = () => {
  const { auth } = useAuth();
  const { loading, response, error, fetch }: DevicesResponseType =
    useUserDevices(auth.token, auth.user?.sub, true);
  const navigate = useNavigate();
  const activeDeviceId = getFromLocalStorage("activeDeviceId");
  const sharingToken = getFromLocalStorage("sharingToken");

  React.useEffect(() => {
    if (sharingToken) return;
    if (activeDeviceId) navigate(`/charts/${activeDeviceId}`);
    if (!activeDeviceId) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (response.length > 0) {
      const firstDeviceId = response[0].deviceId;
      setToLocalStorage("activeDeviceId", firstDeviceId);
      navigate(`/charts/${firstDeviceId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (sharingToken) {
    return <SharedDevice sharingToken={sharingToken} refetch={fetch} />;
  }

  return (
    <Box h="100%">
      {loading && <Preloader empty />}
      {!loading && !error && response.length === 0 && <NoDevices />}
      {!loading && error && (
        <Flex h="100%" w="100%" alignItems="center" justifyContent="center">
          <Text fontSize="lg">Response error</Text>
        </Flex>
      )}
    </Box>
  );
};

export default ChartsRoot;
