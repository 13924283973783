import React from "react";
import { Box, useColorMode } from "@chakra-ui/react";

type ListItemProps = {
  children: React.ReactNode,
  last?: boolean,
}

const ListItem: React.FC<ListItemProps> = ({children, last}) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      ml="4"
      pr="4"
      width="auto"
      pb={!last ? 4 : 0}
      mb={!last ? 3 : 0}
      borderBottom={!last ? "1px" : "none"}
      borderColor={colorMode === "dark" ? "whiteAlpha.200" : "gray.200"}
    >
      {children}
    </Box>
  );
};

export default ListItem;
