import { useState } from "react";
import axios from "axios";

type Props = {
  token: string | null | undefined;
  userId: string | undefined;
  deviceId: string | undefined;
  callback?: Function;
};

const useSetShareDevice = ({ token, userId, deviceId, callback }: Props) => {
  const [response, setResponse] = useState<{ [key: string]: any }>({});
  const [error, setError] = useState("");
  const [loading, setloading] = useState(false);

  const baseUrl = process.env.REACT_APP_API_URL;
  const appKey = process.env.REACT_APP_KEY;

  const fetchFirebase = (sharingToken: string) => {
    axios({
      method: "post",
      url: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${appKey}`,
      data: {
        dynamicLinkInfo: {
          domainUriPrefix: "https://sharing.balloonair.tech",
          link: `https://app.balloonair.tech?SharingToken=${sharingToken}`,
          androidInfo: {
            androidPackageName: "com.balloon.balloonair",
          },
          iosInfo: {
            iosBundleId: "com.balloon.balloonair",
            iosAppStoreId: "1543427189",
          },
        },
      },
    })
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const fetch = () => {
    setloading(true);
    axios({
      method: "post",
      url: `${baseUrl}/api/devices/${userId}/${deviceId}/share`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        fetchFirebase(res.data.token)
      })
      .catch((err) => {
        setError(err);
      })
  };

  // custom hook returns value
  return { response, error, loading, fetch };
};

export default useSetShareDevice;
