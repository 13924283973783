import { ColorModeSwitcher } from "components";
import { Box, Flex, Text, Image, useColorMode } from "@chakra-ui/react";
import logoImage from "assets/images/logo.svg";

function Header() {
  const { colorMode } = useColorMode();
  return (
    <Box
      as="header"
      position="absolute"
      top="0"
      left="0"
      w="100%"
      zIndex={1}
      h="80px"
    >
      <Flex alignItems="center" justifyContent="space-between" h="100%" px="6">
        <Flex
          justifyContent="center"
          backgroundColor={
            colorMode === "light" ? "gray.100" : "whiteAlpha.200"
          }
          borderRadius="25"
          px={6}
          py={2}
        >
          <Image src={logoImage} alt="logo" w="24px" mr={2} />
          <Text fontWeight="600" fontSize="lg">
            BALLOONAIR
          </Text>
        </Flex>
        <ColorModeSwitcher />
      </Flex>
    </Box>
  );
}

export default Header