import React from "react";
import { useAuth } from "hooks";
import { useDeviceSettings, useDeviceInfo, useShareStatus } from "api";
import { useParams, useNavigate } from "react-router-dom";
import {
  DeviceResponseType,
  ShareStatusResponseType,
  DeviceSettingsResponseType,
} from "duck";
import {
  Box,
  Flex,
  Heading,
  Skeleton,
  Badge,
  Grid,
  IconButton,
} from "@chakra-ui/react";
import { AiOutlineLeft } from "react-icons/ai";
import {
  MainBlock,
  AboutBlock,
  SharingBlock,
  NameEditor,
  DeleteBlock,
} from "./components";
import { getOnlineStatus } from "utils";
import { BoxWrapper } from "components/ui";
import { ResponseError } from "components"

const Settings = () => {
  const { auth } = useAuth();
  const { deviceId } = useParams();
  const navigate = useNavigate();

  const {
    loading: deviceLoading,
    response: deviceResponse,
    error: deviceError,
    fetch: deviceFetch,
  }: DeviceResponseType = useDeviceInfo(auth.token, auth.user?.sub, deviceId);


  const { loading, response, error, fetch }: DeviceSettingsResponseType = useDeviceSettings(
    auth.token,
    auth.user?.sub,
    deviceId
  );

  // TODO error check
  const {
    loading: shareStatusLoading,
    response: shareStatusResponse,
    error: shareStatusError,
    fetch: shareStatusFetch,
  }: ShareStatusResponseType = useShareStatus(
    auth.token,
    auth.user?.sub,
    deviceId
  );

  const refetchData = React.useCallback(() => {
    deviceFetch()
    fetch()
    shareStatusFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onlineStatus = React.useMemo(
    () => getOnlineStatus(deviceResponse),
    [deviceResponse]
  );

  if (deviceError || error)
    return (
      <Flex flexDirection="column" h="100%">
        <ResponseError reload={refetchData} />
      </Flex>
    );

  return (
    <Box>
      {/* Heading */}
      <Flex alignItems="center" mb="6">
        <IconButton
          icon={<AiOutlineLeft />}
          aria-label="Back to"
          size="md"
          mr="2"
          borderRadius="100%"
          onClick={() => navigate(-1)}
        />
        <Heading fontSize={{ base: "2xl", md: "3xl" }}>Device Settings</Heading>
      </Flex>
      <Box>
        {/* Skeleton */}
        {deviceLoading && (
          <Flex
            flexDirection="column"
            alignItems={{ base: "center", md: "start" }}
          >
            <Skeleton
              w="100%"
              h="18px"
              maxW="60px"
              mb="4"
              isLoaded={!loading}
            ></Skeleton>
            <Skeleton
              w="100%"
              h="76px"
              maxW="250px"
              mb="4"
              isLoaded={!loading}
            ></Skeleton>
          </Flex>
        )}
        {/* Badge */}
        {!deviceLoading && deviceResponse && (
          <Flex justifyContent={{ base: "center", md: "start" }}>
            <Badge
              colorScheme={onlineStatus ? "green" : "red"}
              variant="outline"
              mb="2"
              borderRadius="10"
              px="2"
            >
              {onlineStatus ? "Online" : "Offline"}
            </Badge>
          </Flex>
        )}
        {/* Name */}
        {deviceResponse?.name && (
          <Box mb="6">
            <NameEditor
              name={deviceResponse.name}
              token={auth.token}
              userId={auth.user?.sub}
              deviceId={deviceId}
            />
          </Box>
        )}
        {/* Shared badge */}
        {deviceResponse?.mode === "Shared" && (
          <Grid
            templateColumns={{ base: "1fr", md: "0.6fr 0.4fr" }}
            maxW="1000px"
            gap={4}
          >
            <BoxWrapper smallRadius>
              <Flex px="4" py="2" fontSize="sm">
                <Flex alignItems="center">
                  <Badge
                    colorScheme="purple"
                    variant="outline"
                    mr="3"
                    height="fit-content"
                    borderRadius="10"
                    px="2"
                  >
                    Shared
                  </Badge>
                </Flex>
                <span>
                  You have been given access to this device. It's control
                  options are limited.
                </span>
              </Flex>
            </BoxWrapper>
          </Grid>
        )}
      </Box>
      <Grid
        templateColumns={{ base: "1fr", md: "0.6fr 0.4fr" }}
        maxW="1000px"
        gap={4}
      >
        <Box>
          {loading && (
            <Skeleton
              height="586px"
              maxW="600px"
              mb="4"
              isLoaded={!loading}
            ></Skeleton>
          )}
          {/* Main settings */}
          {response.length > 0 && !loading && <MainBlock data={response} />}
        </Box>
        <Flex flexDirection="column" gap={4}>
          {/* About */}
          {deviceLoading && (
            <Skeleton height="241px" isLoaded={!deviceLoading}></Skeleton>
          )}
          {!deviceLoading && response.length > 0 && deviceResponse && (
            <AboutBlock data={deviceResponse} />
          )}
          {shareStatusLoading && (
            <Skeleton height="167px" isLoaded={!shareStatusLoading}></Skeleton>
          )}
          {!shareStatusLoading && response.length > 0 && shareStatusResponse && (
            <SharingBlock
              token={auth.token}
              userId={auth.user?.sub}
              data={shareStatusResponse}
              fetch={shareStatusFetch}
            />
          )}
        </Flex>
      </Grid>
      {/* Delete block */}
      {loading && (
        <Skeleton
          height="40px"
          maxW="160px"
          mb="4"
          isLoaded={!loading}
        ></Skeleton>
      )}
      {!loading && response && (
        <DeleteBlock
          userId={auth.user?.sub}
          deviceId={deviceId}
          token={auth.token}
        />
      )}
    </Box>
  );
};

export default Settings;
