import React from "react";
import {} from "react-icons/all";
import {
  Flex,
  Box,
  Menu,
  Link,
  IconButton,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { ReactElement } from "react";

interface NavItemTypes {
  navSize: string | undefined;
  title: string;
  icon: ReactElement;
  to: string;
  isActive: boolean;
}

const NavItem: React.FC<NavItemTypes> = (props: NavItemTypes) => {
  const { navSize, title, icon, to, isActive } = props;
  const { colorMode } = useColorMode();
  const getBackgroundColor = () => {
    if (colorMode === "light") {
      if (isActive) return "white";
      return "gray.100";
    }
    if (colorMode === "dark") {
      if (isActive) return "gray.800";
      return "none";
    }
  };
  const getDecorStyles = (position: string) => {
    let borderStyle;
    let positionStyle;

    if (colorMode === "light") {
      borderStyle = { backgroundColor: "gray.100"};
    } else {
      borderStyle = { backgroundColor: "whiteAlpha.200"};
    }
    if (position === "top") {
      positionStyle = {borderBottomRightRadius: "100%"}
    } else {
      positionStyle = {borderTopRightRadius: "100%"};
    }

    return {
      content: "''",
      position: "absolute",
      right: "0",
      top: "0",
      width: "25px",
      height: "25px",
      transition: "all 0s ease",
      ...positionStyle,
      ...borderStyle,
    };
  };
  
  return (
    <Flex mb={0} mx={{base: 0, md: 0}} direction="column" w="100%">
      <Menu placement="right">
        <Link
          as={ReactRouterLink}
          to={to}
          position="relative"
          textDecoration="none"
          _hover={{ textDecoration: "none" }}
          pr={navSize === "small" ? "0" : "3"}
          pl={{nase: 0, md: 1}}
          py={2}
          backgroundColor={getBackgroundColor()}
          borderRadius={{base: "0 0 25px 25px",md: "25px 0 0 25px"}}
          w="100%"
          transition="all 0s ease"
        >
          <Box
            opacity={isActive ? "1" : "0"}
            position="absolute"
            right={{base: "-25px", md: "0"}}
            transform={{base: "rotate(180deg)", md: "none"}}
            bottom={{base: "calc(100% - 25px)",md: "100%"}}
            width="25px"
            height="25px"
            backgroundColor={colorMode === "light" ? "white" : "gray.800"}
            zIndex="1"
            transition="all 0s ease"
            _before={getDecorStyles("top")}
          />
          <Box
            opacity={isActive ? "1" : "0"}
            position="absolute"
            right={{base: "100%", md: "0"}}
            top={{base: 0, md:"100%"}}
            width="25px"
            height="25px"
            backgroundColor={colorMode === "light" ? "white" : "gray.800"}
            zIndex="1"
            transition="all 0s ease"
            _before={getDecorStyles("bottom")}
          />
          <Flex alignItems="center">
            <IconButton
              icon={icon}
              aria-label={title}
              mr={{base: 2, md: 0}}
              ml={{base: 2, md: 3}}
              fontSize="lg"
              size="sm"
              backgroundColor="transparent"
              color={isActive ? "primary.500" : ""}
              _hover={{ backgroundColor: "transparent" }}
              _active={{ backgroundColor: "transparent" }}
              zIndex={2}
            />
            <Text
              as="span"
              display={navSize === "small" ? "none" : "flex"}
              fontWeight={isActive ? "700" : "400"}
            >
              {title}
            </Text>
          </Flex>
        </Link>
      </Menu>
    </Flex>
  );
};

export default NavItem;
