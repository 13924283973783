import {
  Flex,
  Text,
  Button,
} from "@chakra-ui/react";
import { AiOutlineReload } from "react-icons/ai";

interface ResponseErrorProps {
  reload: Function;
} 

const ResponseError = ({ reload }: ResponseErrorProps) => {
  return (
    <Flex
      flexDirection="column"
      h="100%"
      w="100%"
      alignItems="center"
      justifyContent="center"
      mb="10"
    >
      <Text fontSize="lg" textAlign="center">
        Response error
      </Text>
      <Text fontSize="md" mb="6" textAlign="center" color="gray">
        Something went wrong, please try again later.
      </Text>
      <Button
        leftIcon={<AiOutlineReload />}
        colorScheme="blue"
        onClick={() => reload()}
      >
        Reload
      </Button>
    </Flex>
  );
};

export default ResponseError;