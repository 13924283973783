import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import { useParams } from "react-router-dom";
import { Box, Flex, Skeleton, Grid, GridItem } from "@chakra-ui/react";
import { useAuth, useTabActive } from "hooks";
import { getOnlineStatus, } from "utils";
import { useDeviceInfo, useDeviceLastData, useGetScaledData } from "api";
import { DeviceResponseType, DeviceLastDataResponseType } from "duck";
import { ResponseError, NoData } from "components";
import {
  Head,
  LastData,
  ScaleBar,
  PeriodBar,
  Co2,
  Temperature,
  Humidity,
} from "./components";
import { getScaledTime } from "utils";

dayjs.extend(utc);
dayjs.extend(duration);

const ChartsMain = () => {
  const { auth } = useAuth();
  const { deviceId } = useParams();
  const [scale, setScale] = React.useState("hour");
  const [period, setPeriod] = React.useState(0);
  const [till, setTill] = React.useState(dayjs.utc());
  const [since, setSince] = React.useState(
    dayjs.utc().subtract((period - 1) * -1, "hour")
  );
  const [reloadSeconds, setReloadSeconds] = React.useState({
    pause: false,
    val: 60,
  });
  const tabActive = useTabActive();

  const getScaledData = (scale: string, period: number) => {
    const { tillTime, sinceTime, tillTimeDayjs, sinceTimeDayjs } =
      getScaledTime(scale, period);
    setTill(tillTimeDayjs);
    setSince(sinceTimeDayjs);
    fetch(sinceTime, tillTime, scale);
  };

  const getDeviceData = () => {
    deviceFetch();
    lastDataFetch();
    getScaledData(scale, period);
    setReloadSeconds({ pause: false, val: 60 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  React.useEffect(() => {
    if (period < 0) {
      setReloadSeconds({ pause: true, val: reloadSeconds.val });
      getScaledData(scale, period);
    } else {
      setReloadSeconds({ pause: false, val: reloadSeconds.val });
      getScaledData(scale, period);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale, period]);

  React.useEffect(() => {
    const intervalSec = setInterval(() => {
      if (tabActive && reloadSeconds.val > 0 && reloadSeconds.pause === false) {
        setReloadSeconds({
          pause: reloadSeconds.pause,
          val: (reloadSeconds.val = reloadSeconds.val - 1),
        });
      }
      if (
        tabActive &&
        reloadSeconds.val === 0 &&
        reloadSeconds.pause === false
      ) {
        setReloadSeconds({ pause: false, val: 60 });
        getDeviceData();
      }
    }, 1000);
    return () => {
      clearInterval(intervalSec);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabActive, reloadSeconds]);


  const {
    loading: deviceLoading,
    response: deviceResponse,
    error: deviceError,
    fetch: deviceFetch,
  }: DeviceResponseType = useDeviceInfo(auth.token, auth.user?.sub, deviceId);

  const {
    loading: lastDataLoading,
    response: lastDataResponse,
    error: lastDataError,
    fetch: lastDataFetch,
  }: DeviceLastDataResponseType = useDeviceLastData(
    auth.token,
    auth.user?.sub,
    deviceId
  );

  const {
    loading: dataLoading,
    state: dataResponse,
    error: dataError,
    fetch,
  }: any = useGetScaledData(auth.token, auth.user?.sub, deviceId);

  const onlineStatus = React.useMemo(
    () => getOnlineStatus(deviceResponse),
    [deviceResponse]
  );

  if (deviceError || lastDataError || dataError)
    return (
      <Flex flexDirection="column" h="100%">
        <ResponseError reload={getDeviceData} />
      </Flex>
    );
  if (!deviceError && deviceResponse?.lastOnlineServer === null)
    return (
      <Flex flexDirection="column" h="100%">
        <NoData reload={getDeviceData} />
      </Flex>
    );

  return (
    <Box>
      {/* Head skeleton */}
      {deviceLoading || lastDataLoading ? (
        <Grid
          templateColumns="repeat(4, 1fr)"
          gap={{ base: 2, md: 4 }}
          mb={{ base: 2, md: 4 }}
        >
          <GridItem colSpan={3}>
            <Skeleton height={{ base: "48px", md: "40px" }} />
          </GridItem>
          <GridItem colSpan={1}>
            <Skeleton height={{ base: "48px", md: "40px" }} />
          </GridItem>
        </Grid>
      ) : null}
      {/* Head */}
      {!deviceLoading && !lastDataLoading && (
        <Head data={deviceResponse} refetchClick={getDeviceData} />
      )}
      {/* Last data skeleton */}
      {deviceLoading || lastDataLoading ? (
        <Grid
          templateRows="1fr, auto"
          templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(4, 1fr)" }}
          gap={{ base: 2, md: 4 }}
          mb={4}
        >
          <GridItem colSpan={3} order={{ base: 1, md: 0 }}>
            <Grid
              gap={{ base: 2, md: 4 }}
              templateRows="1fr, 1fr"
              templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(3, 1fr)" }}
            >
              <GridItem
                colSpan={{ base: 2, md: 1 }}
                rowSpan={{ base: 2, md: 1 }}
              >
                <Skeleton height={{ base: "174px", md: "110px" }} />
              </GridItem>
              <GridItem>
                <Skeleton height={{ base: "83px", md: "110px" }} />
              </GridItem>
              <GridItem>
                <Skeleton height={{ base: "83px", md: "110px" }} />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem
            colStart={3}
            colSpan={{ base: 1, md: 1 }}
            order={{ base: 0, md: 3 }}
          >
            <Skeleton height={{ base: "38px", md: "110px" }} />
          </GridItem>
        </Grid>
      ) : null}
      {/* Last data */}
      {!deviceLoading && !lastDataLoading && lastDataResponse.date && (
        <LastData
          data={lastDataResponse}
          reloadSeconds={reloadSeconds}
          onlineStatus={onlineStatus}
        />
      )}
      {/* Scale Bars Skeleton */}
      {lastDataLoading || deviceLoading ? (
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          gap={{ base: "2", md: "4" }}
          mb={4}
        >
          <GridItem>
            <Skeleton height="48px" />
          </GridItem>
          <GridItem>
            <Skeleton height="48px" />
          </GridItem>
        </Grid>
      ) : null}
      {/* Scale Bars */}
      {!lastDataLoading && !deviceLoading && lastDataResponse.date && (
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          gap={{ base: "2", md: "4" }}
          mb={4}
        >
          <GridItem w="100%">
            <PeriodBar
              scale={scale}
              period={period}
              setPeriod={setPeriod}
              tillTime={till}
              sinceTime={since}
              reload={getDeviceData}
            />
          </GridItem>
          <GridItem w="100%">
            <ScaleBar scale={scale} setScale={setScale} setPeriod={setPeriod} />
          </GridItem>
        </Grid>
      )}
      {/* Bars skeleton */}
      {dataLoading || lastDataLoading || deviceLoading ? (
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          gap={{ base: 2, md: 4 }}
          mb={{ base: 20, md: 0 }}
        >
          <GridItem>
            <Skeleton height={{ base: "214px", md: "326px" }} />
          </GridItem>
          <GridItem>
            <Grid templateColumns="repeat(1, 1fr)" gap={{ base: 2, md: 4 }}>
              <GridItem>
                <Skeleton height={{ base: "134px", md: "155px" }} />
              </GridItem>
              <GridItem>
                <Skeleton height={{ base: "134px", md: "155px" }} />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      ) : null}
      {/* Bars */}
      {!dataLoading && !lastDataLoading && !deviceLoading ? (
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          gap={{ base: 2, md: 4 }}
          mb={{ base: 20, md: 0 }}
        >
          <GridItem>
            <Co2 data={dataResponse.co2} scale={scale} />
          </GridItem>
          <GridItem>
            <Grid templateColumns="repeat(1, 1fr)" gap={{ base: 2, md: 4 }}>
              <GridItem>
                <Temperature data={dataResponse.temp} scale={scale} />
              </GridItem>
              <GridItem>
                <Humidity data={dataResponse.hum} scale={scale} />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      ) : null}
    </Box>
  );
};

export default ChartsMain;
