import React from 'react'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { Preloader } from "components"
import { useAuth } from "hooks";

const AuthCallback: React.FC = () => {
  const { setAuth } = useAuth();
  const code_verifier = localStorage.getItem("code_verifier");
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_API_URL;
  const callbackUrl = process.env.REACT_APP_CALLBACK_URL;

  const credentials = {
    grant_type: "authorization_code",
    code: code,
    redirect_uri: callbackUrl,
    code_verifier: code_verifier,
    client_id: "web.app",
    scope:
      "openid profile email balloon.iot.device.api device.read device.write balloon.iot.data.api data.read balloon.iot.push.api push.manage balloon.iot.account.api account.delete",
  };

  const data = qs.stringify(credentials);

  React.useEffect(() => {
    axios({
      method: "post",
      url: `${baseUrl}/connect/token`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    }).then(
      (response) => {
        localStorage.setItem("token", response.data.access_token);
        localStorage.removeItem("code_verifier");
        localStorage.removeItem("code_challenge");
        setAuth({
          isAuthenticating: false,
          isLoggedIn: false,
          user: null,
          token: response.data.access_token,
        });
        window.location.replace("/");
      },
      (error) => {
        console.log(error);
      }
    );
  }, [data, setAuth, navigate]);

  return <Preloader />;
};

export default AuthCallback;
