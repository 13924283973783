import { FormControl, FormLabel, Select, useColorMode } from "@chakra-ui/react";
import { useSetDeviceSetting } from "api";
import { ListItem } from "components/ui";

type LedModeSelectorProps = {
  token: string | null | undefined;
  userId: string | undefined;
  deviceId: string | undefined;
  alias: string;
  data: string;
};

const LedModeSelector = ({
  token,
  userId,
  deviceId,
  alias,
  data,
}: LedModeSelectorProps) => {
  const { colorMode } = useColorMode();
  const ledMode = JSON.parse(data).mode;

  // TODO add loading and error check
  const { fetch } = useSetDeviceSetting(token, userId, deviceId);

  const handleInputChange = (e: string) => {
    fetch({ alias: alias, data: { mode: e } });
  };

  return (
    <ListItem>
      <FormControl>
        <FormLabel
          htmlFor="led-mode"
          mb="1"
          fontSize="sm"
          color={colorMode === "dark" ? "whiteAlpha.700" : "gray.500"}
        >
          LED mode
        </FormLabel>
        <Select
          id="led-mode"
          variant="filled"
          defaultValue={ledMode}
          onChange={(e) => handleInputChange(e.target.value)}
        >
          <option value="on">On</option>
          <option value="fadein">Fade in</option>
          <option value="fadeout">Fade out</option>
          <option value="off">Off</option>
        </Select>
      </FormControl>
    </ListItem>
  );
};

export default LedModeSelector;
