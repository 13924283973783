import {
  ButtonGroup,
  IconButton,
  Flex,
  Input,
  Editable,
  EditableInput,
  EditablePreview,
  useEditableControls,
} from "@chakra-ui/react";
import { AiOutlineEdit, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { useSetDeviceInfo } from "api";

type NameEditorProps = {
  name: string;
  token: string | null | undefined;
  userId: string | undefined;
  deviceId: string | undefined;
};

const NameEditor = ({ name, token, userId, deviceId }: NameEditorProps) => {
  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup size="sm">
        <IconButton
          icon={<AiOutlineCheck />}
          aria-label="Check"
          borderRadius="100%"
          bg="transparent"
          border="1px"
          borderColor="green.300"
          color="green.300"
          {...getSubmitButtonProps()}
        />
        <IconButton
          icon={<AiOutlineClose />}
          aria-label="Close"
          borderRadius="100%"
          bg="transparent"
          border="1px"
          borderColor="red.300"
          color="red.300"
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex>
        <IconButton
          size="sm"
          icon={<AiOutlineEdit />}
          aria-label="Edit"
          borderRadius="100%"
          bg="transparent"
          border="1px"
          borderColor="primary.500"
          color="primary.500"
          {...getEditButtonProps()}
        />
      </Flex>
    );
  }

  // TODO add loading and error check
  const { fetch } = useSetDeviceInfo(
    token,
    userId,
    deviceId,
  );

  const handleInputChange = (e: any) => {
    fetch({name: e});
  };

  return (
    <Editable
      defaultValue={name}
      onSubmit={(e) => handleInputChange(e)}
      fontSize="2xl"
      isPreviewFocusable={false}
      display="flex"
      flexDirection="column"
      alignItems={{base: "center", md: "start"}}
    >
      <EditablePreview fontWeight="700" pt="0" textAlign="center" lineHeight="1.3" />
      <Input as={EditableInput} />
      <EditableControls />
    </Editable>
  );
};

export default NameEditor;
