import * as React from "react"
import {
  Flex,
  useColorMode,
  IconButton,
  IconButtonProps,
  Switch,
} from "@chakra-ui/react";
import { AiOutlineBulb, AiFillBulb } from "react-icons/ai";

type ColorModeSwitcherProps = Omit<IconButtonProps, "aria-label">

export const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = (props) => {
  const { toggleColorMode, colorMode } = useColorMode();

  return (
    <Flex
      backgroundColor={colorMode === "light" ? "gray.100" : "whiteAlpha.100"}
      borderRadius="25px"
      px={{ base: 0, md: 3 }}
      height={{ base: "fit-content", md: "43px" }}
      alignItems="center"
    >
      <IconButton
        display={{ base: "none", md: "flex" }}
        size="xs"
        fontSize="sm"
        variant="ghost"
        color={colorMode === "dark" ? "#4673e5" : "gray.500"}
        backgroundColor="transparent"
        borderRadius="25px"
        onClick={toggleColorMode}
        icon={<AiOutlineBulb />}
        aria-label={`Switch to ${colorMode} mode`}
        _hover={{ backgroundColor: "transparent" }}
        _active={{ backgroundColor: "transparent" }}
        {...props}
      />
      <Flex alignItems="center" display={{ base: "none", md: "flex" }}>
        <Switch
          size="lg"
          isChecked={colorMode === "light" ? true : false}
          onChange={toggleColorMode}
        />
      </Flex>
      <IconButton
        display={{ base: "none", md: "flex" }}
        size="xs"
        fontSize={{ base: "lg", md: "sm" }}
        variant="ghost"
        color={colorMode === "light" ? "#4673e5" : "gray.500"}
        backgroundColor="transparent"
        borderRadius="25px"
        onClick={toggleColorMode}
        icon={<AiFillBulb />}
        aria-label={`Switch to ${colorMode} mode`}
        _hover={{ backgroundColor: "transparent" }}
        _active={{ backgroundColor: "transparent" }}
        {...props}
      />
      <IconButton
        display={{ base: "flex", md: "none" }}
        size="md"
        fontSize="lg"
        variant="ghost"
        color={colorMode === "light" ? "#4673e5" : "white"}
        backgroundColor="transparent"
        borderRadius="25px"
        onClick={toggleColorMode}
        icon={colorMode === "light" ? <AiFillBulb /> : <AiOutlineBulb />}
        aria-label={`Switch to ${colorMode} mode`}
        _hover={{ backgroundColor: "transparent" }}
        _active={{ backgroundColor: "transparent" }}
        {...props}
      />
    </Flex>
  );
}

export default ColorModeSwitcher;
