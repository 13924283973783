import React from "react";
import { Sidebar, Header } from "../../components";
import { Outlet } from "react-router-dom";
import { Box, Flex, useColorMode } from "@chakra-ui/react";
//import lineImage from "assets/images/back-line.svg";

const Layout: React.FC = () => {
  const { colorMode } = useColorMode();
  const scrollbarColor = colorMode === "dark" ? "whiteAlpha.100" : "gray.100"
  const style = {
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: scrollbarColor,
      borderRadius: "24px",
    },
  };
  return (
    <Flex
      position="relative"
      w="100%"
      h="100vh"
      alignItems="center"
      justifyContent="center"
      bgColor={colorMode === "dark" ? "gray.700" : "gray.200"}
    >
      <Box
        maxW="1400px"
        w="100%"
        h={{ base: "100vh", lg: "calc(100vh - 48px)" }}
        m={{ base: 0, lg: 4 }}
        bgColor={colorMode === "dark" ? "gray.800" : "white"}
        borderRadius={{ base: 0, lg: 40 }}
        boxShadow="2xl"
        overflow="hidden"
      >
        <Flex
          h={{ base: "100vh", lg: "calc(100vh - 48px)" }}
          position="relative"
        >
          <Sidebar />
          <Flex
            as="main"
            w="full"
            flexDirection="column"
            overflowY="hidden"
            mt={{ base: 4, lg: 6 }}
            mb={{ base: 4, lg: 6 }}
            ml={{ base: 4, lg: 6 }}
            zIndex="1"
          >
            <Header />
            <Box
              h="100%"
              overflowY="auto"
              overflowX="hidden"
              pt="2"
              pb={{ base: 20, md: 0 }}
              pr={{ base: 3, lg: 5 }}
              mr={{ base: 1, lg: 1 }}
              sx={style}
            >
              <Outlet />
            </Box>
          </Flex>
          {/* <Image
            src={lineImage}
            alt="Background Line"
            w="100%"
            maxW="none"
            position="absolute"
            bottom="4"
            left="0"
            zIndex="0"
            opacity="0.3"
          /> */}
        </Flex>
      </Box>
    </Flex>
  );
};

export default Layout;
