export const co2ThresholdData = [
  { data: 400, value: "400ppm" },
  { data: 450, value: "450ppm" },
  { data: 500, value: "500ppm" },
  { data: 550, value: "550ppm" },
  { data: 600, value: "600ppm" },
  { data: 650, value: "650ppm" },
  { data: 700, value: "700ppm" },
  { data: 750, value: "750ppm" },
  { data: 800, value: "800ppm" },
  { data: 850, value: "850ppm" },
  { data: 900, value: "900ppm" },
  { data: 950, value: "950ppm" },
  { data: 1000, value: "1000ppm" },
  { data: 1050, value: "1050ppm" },
  { data: 1100, value: "1100ppm" },
  { data: 1150, value: "1150ppm" },
  { data: 1200, value: "1200ppm" },
  { data: 1250, value: "1250ppm" },
  { data: 1300, value: "1300ppm" },
  { data: 1350, value: "1350ppm" },
  { data: 1400, value: "1400ppm" },
  { data: 1450, value: "1450ppm" },
  { data: 1500, value: "1500ppm" },
  { data: 1550, value: "1550ppm" },
  { data: 1600, value: "1600ppm" },
  { data: 1650, value: "1650ppm" },
  { data: 1700, value: "1700ppm" },
  { data: 1750, value: "1750ppm" },
  { data: 1800, value: "1800ppm" },
  { data: 1850, value: "1850ppm" },
  { data: 1900, value: "1900ppm" },
  { data: 1950, value: "1950ppm" },
  { data: 2000, value: "2000ppm" },
  { data: 2100, value: "2100ppm" },
  { data: 2200, value: "2200ppm" },
  { data: 2300, value: "2300ppm" },
  { data: 2400, value: "2400ppm" },
  { data: 2500, value: "2500ppm" },
  { data: 2600, value: "2600ppm" },
  { data: 2700, value: "2700ppm" },
  { data: 2800, value: "2800ppm" },
  { data: 2900, value: "2900ppm" },
  { data: 3000, value: "3000ppm" },
  { data: 3500, value: "3500ppm" },
  { data: 4000, value: "4000ppm" },
  { data: 4500, value: "4500ppm" },
  { data: 5000, value: "5000ppm" },
];

export const tempUnits = [
  { key: "Celsius (°С)", value: "celsius" },
  { key: "Fahrenheit (°F)", value: "fahrenheit" },
];

export const chartsScheme = [
  { key: "Use app theme", value: "app" },
  { key: "Purple", value: "purple" },
  { key: "Yellow", value: "yellow" },
];

export const weekStarts = [
  { key: "Monday", value: "monday" },
  { key: "Sunday", value: "sunday" },
  { key: "Saturday", value: "saturday" },
];

export const appTheme = [
  //{ key: "Like system mode", value: "system" },
  { key: "Light", value: "light" },
  { key: "Dark", value: "dark" },
];

export const barColors: {[key: string]: number[]} = {
  purple: [
    0x394cf2, 0x3949f3, 0x3946f4, 0x3943f5, 0x3940f7, 0x393df8, 0x393af9,
    0x3937fa, 0x3935fc, 0x3935fc, 0x4934fb, 0x5933fb, 0x6933fb, 0x7932fb,
    0x8931fa, 0x9931fa, 0xa930fa, 0xb930fa, 0xb930fa, 0xbc2ff3, 0xbf2eec,
    0xc22ee5, 0xc62dde, 0xc92dd7, 0xcc2cd0, 0xd02cc9, 0xd32bc2, 0xd62abb,
    0xda2ab4, 0xdd29ad, 0xe029a6, 0xe4289f, 0xe72898, 0xea2791, 0xee278b,
    0xee278b, 0xef2688, 0xf02686, 0xf12684, 0xf22581, 0xf3257f, 0xf4257d,
    0xf5247a, 0xf62478, 0xf72476, 0xf82373, 0xf92371, 0xfa236f, 0xfb226c,
    0xfc226a, 0xfd2268, 0xfe2266, 0xfe2266, 0xfe2266, 0xfe2266, 0xfe2266,
    0xfe2266, 0xfe2266, 0xfe2266, 0xfe2266, 0xfe2266, 0xfe2266, 0xfe2266,
    0xfe2266, 0xfe2266, 0xfe2266, 0xfe2266, 0xfe2266, 0xfe2266,
  ],
  yellow: [
    0xedd3aa, 0xedd3aa, 0xedd3aa, 0xedd3aa, 0xedd3aa, 0xedd3aa, 0xedd3aa,
    0xedd3aa, 0xedd3aa, 0xedcea6, 0xedcaa2, 0xedc69e, 0xedc29a, 0xedbd96,
    0xedb992, 0xedb58e, 0xedb18b, 0xedb18b, 0xecad87, 0xecaa84, 0xeca781,
    0xeca47e, 0xeca17a, 0xeb9d77, 0xeb9a74, 0xeb9771, 0xeb946d, 0xeb916a,
    0xea8d67, 0xea8a64, 0xea8760, 0xea845d, 0xea815a, 0xea7e57, 0xea7e57,
    0xea7954, 0xea7451, 0xea6f4f, 0xeb6a4c, 0xeb6549, 0xeb6047, 0xeb5b44,
    0xec5642, 0xec513f, 0xec4c3c, 0xec473a, 0xed4237, 0xed3d34, 0xed3832,
    0xed332f, 0xee2e2d, 0xee2e2d, 0xef2b2a, 0xf02827, 0xf12524, 0xf22221,
    0xf31f1e, 0xf41c1c, 0xf51919, 0xf61716, 0xf71413, 0xf81110, 0xf90e0e,
    0xfa0b0b, 0xfb0808, 0xfc0505, 0xfd0202, 0xff0000,
  ],
};


