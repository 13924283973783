import React from 'react'
import { Button, Flex, useColorMode } from '@chakra-ui/react';
import { BoxWrapper } from 'components/ui';

type ScaleBarProps = {
  scale: string;
  setScale: React.Dispatch<React.SetStateAction<string>>;
  setPeriod: React.Dispatch<React.SetStateAction<number>>;
};

const ScaleBar = ({ scale, setScale, setPeriod }: ScaleBarProps) => {
  const { colorMode } = useColorMode();
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setScale(e.currentTarget.id);
    setPeriod(0);
  };
  return (
    <BoxWrapper noPadding noMargin>
      <Flex p="1">
        <Button
          id="hour"
          variant="ghost"
          colorScheme="gray"
          fontWeight="400"
          fontSize="sm"
          borderRadius="2rem"
          width="100%"
          onClick={handleClick}
          _active={{
            backgroundColor: `${
              colorMode === "dark" ? "whiteAlpha.300" : "white"
            }`,
            fontWeight: "700",
          }}
          isActive={scale === "hour" ? true : false}
        >
          Hour
        </Button>
        <Button
          id="day"
          variant="ghost"
          colorScheme="gray"
          fontWeight="400"
          fontSize="sm"
          borderRadius="2rem"
          width="100%"
          onClick={handleClick}
          _active={{
            backgroundColor: `${
              colorMode === "dark" ? "whiteAlpha.300" : "white"
            }`,
            fontWeight: "700",
          }}
          mx="1"
          isActive={scale === "day" ? true : false}
        >
          Day
        </Button>
        <Button
          id="week"
          variant="ghost"
          colorScheme="gray"
          fontWeight="400"
          fontSize="sm"
          borderRadius="2rem"
          width="100%"
          onClick={handleClick}
          _active={{
            backgroundColor: `${
              colorMode === "dark" ? "whiteAlpha.300" : "white"
            }`,
            fontWeight: "700",
          }}
          isActive={scale === "week" ? true : false}
        >
          Week
        </Button>
      </Flex>
    </BoxWrapper>
  );
};

export default ScaleBar