import { Route, Routes } from "react-router-dom";
import { ChartsRoot, ChartsMain } from "./components";

const Charts = () => {
  return (
    <Routes>
      <Route path="*" element={<ChartsRoot />} />
      <Route path="/:deviceId" element={<ChartsMain />} />
    </Routes>
  );
}

export default Charts